<template>
  <section class="brand-one brand-one__home-one my-5">
    <div class="container">
      <div class="block-title text-center">
        <span class="block-title__bubbles"></span>
        <p></p>
        <h3 class="text-white"> Partnerships </h3>
      </div>

      <div class="brand-one__carousel owl-carousel thm__owl-carousel owl-theme">
        <div class="item">
          <img src="./../assets/images/brand/aws.png" alt="">
        </div>
        <div class="item">
          <img src="./../assets/images/brand/binance.png" alt="">
        </div>
        <div class="item">
          <img src="./../assets/images/brand/c.png" alt="">
        </div>
        <div class="item">
          <img src="./../assets/images/brand/coinpanel.png" alt="">
        </div>
        <div class="item">
          <img src="./../assets/images/brand/crypBooster.png" alt="">
        </div>
        <div class="item">
          <img src="./../assets/images/brand/kommunitas.png" alt="">
        </div>
        <div class="item">
          <img src="./../assets/images/brand/marketWatch.png" alt="">
        </div>
        <div class="item">
          <img src="./../assets/images/brand/metamask.png" alt="">
        </div>
        <div class="item">
          <img src="./../assets/images/brand/OpenSea.png" alt="">
        </div>
        <div class="item">
          <img src="./../assets/images/brand/polygon.png" alt="">
        </div>
        <div class="item">
          <img src="./../assets/images/brand/SL.png" alt="">
        </div>
        <div class="item">
          <img src="./../assets/images/brand/satoshi_club.png" alt="">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
    export default {
        name: "Brands-partners"
    }
</script>

<style scoped>

</style>
