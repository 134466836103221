<template>
  <div>
  </div>
</template>
<script>
  import axios from 'axios';

  export default {
    name: "Terms-Conditions",
    components: {
    },
    data() {
      return {
        name: process.env.VUE_APP_NAME,
        title: "Terms & Conditions",
        termsLink: require("/src/assets/files/termsAndConditions.pdf"),
      }
    },
    mounted() {
      axios.get(this.termsLink, { responseType: 'blob' })
              .then(response => {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = "EDT_TermsAndConditions.pdf";
                link.click();
                URL.revokeObjectURL(link.href);
                this.$router.push("/");
              }).catch(console.error);
    }
  }
</script>

<style scoped>
</style>