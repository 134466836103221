<template>
  <div>

  </div>
</template>

<script>
    import axios from 'axios';
    export default {
        name: "companyRegistration-download",
        data() {
            return {
                name: process.env.VUE_APP_NAME,
                wpLink: require("/src/assets/files/visura_camerale.pdf"),
            }
        },
        mounted() {
            axios.get(this.wpLink, { responseType: 'blob' })
                .then(response => {
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = "NDF_company_registration.pdf";
                    link.click();
                    URL.revokeObjectURL(link.href);
                    this.$router.push("/");
                }).catch(console.error);
        }
    }
</script>

<style scoped>

</style>
