<template>
  <section class="blog-one blog-one__home" id="team">
    <img src="./../assets/images/shapes/blog-shape-1-1.png" alt="" class="blog-one__shape-1">
    <div class="container">
      <div class="block-title text-center">
        <span class="block-title__bubbles"></span>
        <p></p>
        <h3> Who we are </h3>
      </div>


      <div class="row d-flex">

        <div class="col-md-6 col-lg-5 col-xl-4 mx-auto wow fadeInUp" data-wow-duration="1500ms" data-wow-offset="150">
          <div class="blog-one__single">
            <div class="row d-flex m-1">

              <div class="col-6 p-0">
                <div class="blog-one__image">
                  <img src="./../assets/images/team/Mario.jpg" alt="">
                </div>
              </div>

              <div class="col-6 p-0">
                <div class="blog-one__content">
                  <h5>Mario Ferrari</h5>
                  <p class="capitalize">CEO</p>
                  <p>Former Hewlett-Packard, serial entrepreneur, involved in the Blockchain Industry since 2016, with twenty years of experience in the Automotive and Green Energy sectors</p>
                </div>
              </div>
              <div class="col-12 mt-3 text-center">
                <div class="site-footer__social">
                  <a href="https://www.linkedin.com/in/ferrarimario64/" target="_blank">
                    <font-awesome-icon :icon="['fab', 'linkedin-in']"></font-awesome-icon>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-5 col-xl-4 mx-auto wow fadeInUp" data-wow-duration="1500ms" data-wow-offset="150">
          <div class="blog-one__single">
            <div class="row d-flex m-1">
              <div class="col-6 p-0">
                <div class="blog-one__image">
                  <img src="./../assets/images/team/Pezzato.jpg" alt="">
                </div>
              </div>
              <div class="col-6 p-0">
                <div class="blog-one__content">
                  <h5>Andrea Pezzato</h5>
                  <p class="capitalize">CTO</p>
                  <p>R&D manager with 30 years of financial activity.
                    Strong experience on the Blockchain technology and on the Crowdfunding sector.
                  </p>
                </div>
              </div>
              <div class="col-12 mt-3 text-center">
                <div class="site-footer__social">
                  <a href="https://www.linkedin.com/in/andrea-pezzato-b524ba236/" target="_blank">
                    <font-awesome-icon :icon="['fab', 'linkedin-in']"></font-awesome-icon>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="col-md-6 col-lg-5 col-xl-4 mx-auto wow fadeInUp" data-wow-duration="1500ms" data-wow-offset="150">
          <div class="blog-one__single">
            <div class="row d-flex m-1">
              <div class="col-6 p-0">
                <div class="blog-one__image">
                  <img src="./../assets/images/team/Gianluca.jpg" alt="">
                </div>
              </div>
              <div class="col-6 p-0">
                <div class="blog-one__content">
                  <h5>Gianluca Franco</h5>
                  <p class="capitalize">COO</p>
                  <p>Manager with years of experience in the finance and technology sectors; Background in Operational and managerial roles; Highly skilled in strategic and commercial activities.</p>
                </div>
              </div>
              <div class="col-12 mt-3 text-center">
                <div class="site-footer__social">
                  <a href="https://www.linkedin.com/in/gianluca-franco-92996434" target="_blank">
                    <font-awesome-icon :icon="['fab', 'linkedin-in']"></font-awesome-icon>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="col-md-6 col-lg-5 col-xl-4 mx-auto wow fadeInUp" data-wow-duration="1500ms" data-wow-offset="150">
          <div class="blog-one__single">
            <div class="row d-flex m-1">
              <div class="col-6 p-0">
                <div class="blog-one__image">
                  <img src="./../assets/images/team/Filippo.jpg" alt="">
                </div>
              </div>
              <div class="col-6 p-0">
                <div class="blog-one__content">
                  <h5>Filippo Ferrari</h5>
                  <p class="capitalize">Commercial Area Manager</p>
                  <p>Proven sales specialist with years of experience in the automotive sector; Crypto trader; MOTORSPORTS fanatic.</p>
                </div>
              </div>
              <div class="col-12 mt-3 text-center">
                <div class="site-footer__social">
                  <a href="http://www.linkedin.com/in/ferrarifilippo00" target="_blank">
                    <font-awesome-icon :icon="['fab', 'linkedin-in']"></font-awesome-icon>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>


<!--        <div class="col-md-6 col-lg-5 col-xl-4 mx-auto wow fadeInUp" data-wow-duration="1500ms" data-wow-offset="150">-->
<!--          <div class="blog-one__single">-->
<!--            <div class="row d-flex m-1">-->
<!--              <div class="col-6 p-0">-->
<!--                <div class="blog-one__image">-->
<!--                  <img src="./../assets/images/team/Tommaso.jpg" alt="">-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-6 p-0">-->
<!--                <div class="blog-one__content">-->
<!--                  <h5>Tommaso Ferrari</h5>-->
<!--                  <p class="capitalize">Commercial Area Manager</p>-->
<!--                  <p>Promoter of blockchain technologies. Responsible for customer acquisition and loyalty strategies.-->
<!--                    Manager of the collaboration networks.-->
<!--                  </p>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-12 mt-3 text-center">-->
<!--                <div class="site-footer__social">-->
<!--                  <a href="http://www.linkedin.com/in/ferraritommaso02" target="_blank">-->
<!--                    <font-awesome-icon :icon="['fab', 'linkedin-in']"></font-awesome-icon>-->
<!--                  </a>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

        <div class="col-md-6 col-lg-5 col-xl-4 mx-auto wow fadeInUp" data-wow-duration="1500ms" data-wow-offset="150">
          <div class="blog-one__single">
            <div class="row d-flex m-1">
              <div class="col-6 p-0">
                <div class="blog-one__image">
                  <img src="./../assets/images/team/Petti.jpeg" alt="">
                </div>
              </div>
              <div class="col-6 p-0">
                <div class="blog-one__content">
                  <h5>Niccolò Petti</h5>
                  <p class="capitalize">Blockchain Developer</p>
                  <p>Computer Scientist and Blockchain enthusiast.
                    Expert developer in the field of complex and secure Smart Contracts.
                  </p>
                </div>
              </div>
              <div class="col-12 mt-3 text-center">
                <div class="site-footer__social">
                  <a href="https://www.linkedin.com/in/niccol%C3%B2-petti/" target="_blank">
                    <font-awesome-icon :icon="['fab', 'linkedin-in']"></font-awesome-icon>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div class="col-md-6 col-lg-5 col-xl-4 mx-auto wow fadeInUp" data-wow-duration="1500ms" data-wow-offset="150">
          <div class="blog-one__single">
            <div class="row d-flex m-1">
              <div class="col-6 p-0">
                <div class="blog-one__image">
                  <img src="./../assets/images/team/Reni.jpg" alt="">
                </div>
              </div>
              <div class="col-6 p-0">
                <div class="blog-one__content">
                  <h5>Reni Achkar</h5>
                  <p class="capitalize">Marketing Lead</p>
                  <p>CEO and Founder at The Social Laboratory; Extensive expertise in marketing for cryptocurrency & blockchain projects.</p>
                </div>
              </div>
<!--              <div class="col-12 mt-3 text-center">-->
<!--                <div class="site-footer__social">-->
<!--                  <a href="https://www.linkedin.com/in/vincenzo-piccolo-7b6b704b/" target="_blank">-->
<!--                    <font-awesome-icon :icon="['fab', 'linkedin-in']"></font-awesome-icon>-->
<!--                  </a>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
        </div>



        <div class="col-md-6 col-lg-5 col-xl-4 mx-auto wow fadeInUp" data-wow-duration="1500ms" data-wow-offset="150">
          <div class="blog-one__single">
            <div class="row d-flex m-1">
              <div class="col-6 p-0">
                <div class="blog-one__image">
                  <img src="./../assets/images/team/Alexander.jpg" alt="">
                </div>
              </div>
              <div class="col-6 p-0">
                <div class="blog-one__content">
                  <h5>Alexander Westin</h5>
                  <p class="capitalize">Advisor</p>
                  <p>CEO and Cofounder at CoinPanel; Wide network of strategic contacts with trading communities, top exchanges and market makers</p>
                </div>
              </div>
              <!--              <div class="col-12 mt-3 text-center">-->
              <!--                <div class="site-footer__social">-->
              <!--                  <a href="https://www.linkedin.com/in/vincenzo-piccolo-7b6b704b/" target="_blank">-->
              <!--                    <font-awesome-icon :icon="['fab', 'linkedin-in']"></font-awesome-icon>-->
              <!--                  </a>-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
          </div>
        </div>



        <div class="col-md-6 col-lg-5 col-xl-4 mx-auto wow fadeInUp" data-wow-duration="1500ms" data-wow-offset="150">
          <div class="blog-one__single">
            <div class="row d-flex m-1">
              <div class="col-6 p-0">
                <div class="blog-one__image">
                  <img src="./../assets/images/team/Robby.png" alt="">
                </div>
              </div>
              <div class="col-6 p-0">
                <div class="blog-one__content">
                  <h5>Robby Jeo</h5>
                  <p class="capitalize">Advisor</p>
                  <p>CEO and Cofounder at KOMMUNITAS; Serial entrepreneur, investor, and advisor for many projects in the crypto space; One of the Polygon Advocates for Indonesia Region</p>
                </div>
              </div>
              <!--              <div class="col-12 mt-3 text-center">-->
              <!--                <div class="site-footer__social">-->
              <!--                  <a href="https://www.linkedin.com/in/vincenzo-piccolo-7b6b704b/" target="_blank">-->
              <!--                    <font-awesome-icon :icon="['fab', 'linkedin-in']"></font-awesome-icon>-->
              <!--                  </a>-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
          </div>
        </div>




        <div class="col-md-6 col-lg-5 col-xl-4 mx-auto wow fadeInUp" data-wow-duration="1500ms" data-wow-offset="150">
          <div class="blog-one__single">
            <div class="row d-flex m-1">
              <div class="col-6 p-0">
                <div class="blog-one__image">
                  <img src="./../assets/images/team/Christian.jpg" alt="">
                </div>
              </div>
              <div class="col-6 p-0">
                <div class="blog-one__content">
                  <h5>Christian Crocco</h5>
                  <p class="capitalize">Advisor</p>
                  <p>Entrepreneur, with years of experience in the Motorsport sector, member of a network of car collectors with international contacts. Organizer of car events and competitions</p>
                </div>
              </div>
              <div class="col-12 mt-3 text-center">
                <div class="site-footer__social">
                  <a href="https://www.linkedin.com/in/christiancrocco" target="_blank">
                    <font-awesome-icon :icon="['fab', 'linkedin-in']"></font-awesome-icon>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>




        <div class="col-md-6 col-lg-5 col-xl-4 mx-auto wow fadeInUp" data-wow-duration="1500ms" data-wow-offset="150">
          <div class="blog-one__single">
            <div class="row d-flex m-1">
              <div class="col-6 p-0">
                <div class="blog-one__image">
                  <img src="./../assets/images/team/Enzo.jpg" alt="">
                </div>
              </div>
              <div class="col-6 p-0">
                <div class="blog-one__content">
                  <h5>Vincenzo Piccolo</h5>
                  <p class="capitalize">AML Compliance Advisor</p>
                  <p>Strong experience in the AML and Tax compliance sector, with specific expertise in the
                    cryptocurrency and blockchain fields.</p>
                </div>
              </div>
              <div class="col-12 mt-3 text-center">
                <div class="site-footer__social">
                  <a href="https://www.linkedin.com/in/vincenzo-piccolo-7b6b704b/" target="_blank">
                    <font-awesome-icon :icon="['fab', 'linkedin-in']"></font-awesome-icon>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>





      </div>
    </div>
  </section>
</template>

<script>
    export default {
        name: "Team-section"
    }
</script>

<style scoped>

</style>
