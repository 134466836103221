<template>
  <section class="service-one" id="features">
    <div class="container">
      <div class="block-title text-center">
        <span class="block-title__bubbles"></span>

        <h3>EDriveToken (EDT) Main Objectives</h3>
      </div>
      <div class="row">

        <div class="service-one__col wow fadeInRight scrollToLink" data-wow-duration="2000ms" data-wow-delay="100ms">
          <a href="#ev_sb">
            <div class="service-one__single">
  <!--            <i class="zimed-icon-responsive"></i>-->
              <i><img src="./../assets/images/icons/electriccar_icon.png" style="width: 65px"></i>
              <h3>The Future of Smart Mobility: D2S</h3>
            </div>
          </a>
        </div>

        <div class="service-one__col wow fadeInUp scrollToLink" data-wow-duration="2000ms" data-wow-delay="100ms">
          <a href="#scars">
            <div class="service-one__single">
              <i><img src="./../assets/images/icons/supercar_icon.png" style="width: 90px"></i>
              <h3>Super Cars</h3>
            </div>
          </a>
        </div>

        <div class="service-one__col wow fadeInLeft scrollToLink" data-wow-duration="2000ms" data-wow-delay="100ms">
          <a href="#hcars">
            <div class="service-one__single">
              <i><img src="./../assets/images/icons/vintagecar_icon.png" style="width: 90px"></i>
              <h3>Classic Cars</h3>
            </div>
          </a>
        </div>
<!--        <div class="service-one__col wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">-->
<!--          <div class="service-one__single">-->
<!--            <i class="zimed-icon-development"></i>-->
<!--            <h3>Fully Secured</h3>-->
<!--          </div>&lt;!&ndash; /.service-one__single &ndash;&gt;-->
<!--        </div>&lt;!&ndash; /.service-one__col &ndash;&gt;-->
<!--        <div class="service-one__col wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms">-->
<!--          <div class="service-one__single">-->
<!--            <i class="zimed-icon-strategy"></i>-->
<!--            <h3>Daily Update</h3>-->
<!--          </div>&lt;!&ndash; /.service-one__single &ndash;&gt;-->
<!--        </div>&lt;!&ndash; /.service-one__col &ndash;&gt;-->
      </div>
    </div>
  </section>
</template>

<script>
    export default {
      name: "services-section"
    }
</script>

<style scoped>

</style>
