import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import axiosConfig from "./axiosConfig";
import i18nConfig from "./i18nConfig";
import FontAwesomeIcon from "./fontawesomeConfig";


window.$ = window.jQuery =  require('jquery');
import "jquery.easing";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
const options = { containerClassName: "ct-notification" };

import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";

import "bootstrap/scss/bootstrap.scss";
import "bootstrap/dist/js/bootstrap.min.js";


import "src/assets/css/animate.min.css";
import "src/assets/css/bootstrap-datepicker.min.css";
import "src/assets/css/bootstrap-select.min.css";
import "src/assets/css/jquery.mCustomScrollbar.min.css";
import "src/assets/css/owl.carousel.min.css";
import "src/assets/css/owl.theme.default.min.css";
import "src/assets/css/zimed-icon.css";
import "src/assets/css/magnific-popup.css";
import "src/assets/css/style.css";
import "src/assets/css/responsive.css";



import Wow from "wow.js";
var wow = new Wow({
    boxClass: 'wow', // animated element css class (default is wow)
    animateClass: 'animated', // animation css class (default is animated)
    offset: 250, // distance to the element when triggering the animation (default is 0)
    mobile: true, // trigger animations on mobile devices (default is true)
    live: true // act on asynchronously loaded content (default is true)
});
wow.init();

require('owl.carousel');

import "src/assets/js/bootstrap.bundle.min.js";
import "src/assets/js/TweenMax.min.js";
import "src/assets/js/jquery.mCustomScrollbar.concat.min.js";
import "src/assets/js/bootstrap-datepicker.min.js";
import "src/assets/js/bootstrap-select.min.js";
import "src/assets/js/jquery.validate.min.js";
// import "src/assets/js/waypoints.min.js";
// import "src/assets/js/jquery.counterup.min.js";
// import "src/assets/js/jquery.magnific-popup.min.js";

const appInstance = createApp(App);
appInstance.use(router);
appInstance.use(Toast, options);
appInstance.use(GlobalComponents);
appInstance.use(GlobalDirectives);
appInstance.use(i18nConfig);
appInstance.use(store);
appInstance.use(VueAxios, axios);
appInstance.use(axiosConfig);
appInstance.component("font-awesome-icon", FontAwesomeIcon);
appInstance.mount("#app");
