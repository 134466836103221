<template>
  <section class="blog-one blog-one__home blog-one__grid" style="background-color: white">
    <div class="container">
      <div class="block-title text-center">
        <span class="block-title__bubbles"></span>
        <p></p>
        <h3> Featured on </h3>
      </div>
      <div class="owl-carousel thm__owl-carousel owl-theme">

        <div class="item">
          <div class="blog-one__single">

            <div class="blog-one__image">
              <img src="./../assets/images/blog/techbullion.png" alt="">
            </div>

<!--            <div class="blog-one__content">-->
<!--              <ul class="blog-one__meta list-unstyled">-->
<!--                <li><a href="#"><i class="far fa-clock"></i> 20 Feb</a></li>-->
<!--              </ul>-->

<!--              <h4 class="my-4">EDrive Token: The Next Revolution for Automotive Technology and How it...</h4>-->
<!--              <router-link to="/blog-details" class="blog-one__link"><i class="zimed-icon-right-arrow"></i></router-link>-->

<!--            </div>-->
          </div>
        </div>


        <div class="item">
          <div class="blog-one__single">
            <div class="blog-one__image">
              <img src="./../assets/images/blog/bitcoin_warrior.png" alt="">
            </div>

<!--            <div class="blog-one__content">-->
<!--              <h4 class="my-4">EDrive Token: The Next Revolution for Automotive Technology and How it...</h4>-->
<!--            </div>-->
          </div>
        </div>

        <div class="item">
          <div class="blog-one__single">
            <div class="blog-one__image">
              <img src="./../assets/images/blog/hackernoon.png" alt="">
            </div>

<!--            <div class="blog-one__content">-->
<!--              <h4 class="my-4">Embracing the Future: Ensuring Inclusive Web3 Adoption Across Generations</h4>-->
<!--            </div>-->
          </div>
        </div>



      </div>

<!--      <div class="post-pagination">-->
<!--        <a href="#"><i class="fa fa-angle-left"></i></a>-->
<!--        <a href="#">1</a>-->
<!--        <a href="#" class="active">2</a>-->
<!--        <a href="#">3</a>-->
<!--        <a href="#"><i class="fa fa-angle-right"></i></a>-->
<!--      </div>&lt;!&ndash; /.post-pagination &ndash;&gt;-->
    </div>
  </section>
</template>

<script>
    export default {
        name: "Blog-news"
    }
</script>

<style>
</style>
