<template>
  <section id="roadmap" style="margin-top: 100px; margin-bottom: 100px;">
    <div class="container">

      <div class="row">
        <div class="col-12 block-title text-center">
          <span class="block-title__bubbles"></span>
          <h3 style="font-size: 30px; margin-bottom: 100px">Roadmap</h3>
        </div>
      </div>

      <div class="row wow fadeInUp" data-wow-offset="150">
        <div class="roadmap-col-empty-left col-lg-4"></div>

        <div class="col-md-4">
          <img src="./../assets/images/rm_1.png" alt="" class="roadmap-image-left">
        </div>

        <div class="col-12 col-md-8 col-lg-4">
          <div class="row roadmap-box-row">
            <div class="roadmap-box-text-left col-12 col-sm-8 col-md-9">
              <h5 class="roadmap-box-title">Q2-Q3 2021</h5>
              <p>Development of the Project Idea<br>
                Feasibility Study<br>
                Market Analysis</p>
            </div>

            <div class="roadmap-box-icon-left col-sm-4 col-md-3">
              <font-awesome-icon :icon="['fas', 'lightbulb']" size="3x"></font-awesome-icon>
            </div>
          </div>
        </div>
      </div>



      <div class="row wow fadeInUp" data-wow-offset="150">
        <div class="roadmap-col-box-right col-12 col-md-8 col-lg-4">
          <div class="row roadmap-box-row">
            <div class="roadmap-box-icon-right col-sm-4 col-md-3">
              <font-awesome-icon :icon="['fas', 'building']" size="3x"></font-awesome-icon>
            </div>
            <div class="roadmap-box-text-right col-12 col-sm-8 col-md-9">
              <h5 class="roadmap-box-title">Q4 2021</h5>
              <p>Establishment of the technical, administrative, and commercial team<br>
                Company incorporation</p>
            </div>
          </div>
        </div>

        <div class="roadmap-col-image-right col-md-4">
          <img src="./../assets/images/rm_2.png" alt="" class="roadmap-image-right">
        </div>

        <div class="roadmap-col-empty-right col-lg-4"></div>
      </div>


      <div class="row wow fadeInUp" data-wow-offset="150">
        <div class="roadmap-col-empty-left col-lg-4"></div>

        <div class="col-md-4">
          <img src="./../assets/images/rm_1.png" alt="" class="roadmap-image-left">
        </div>

        <div class="col-12 col-md-8 col-lg-4">
          <div class="row roadmap-box-row">
            <div class="roadmap-box-text-left col-12 col-sm-8 col-md-9">
              <h5 class="roadmap-box-title"> Q1-Q2 2022</h5>
              <p>EDT Private Sale</p>
            </div>

            <div class="roadmap-box-icon-left col-sm-4 col-md-3">
              <font-awesome-icon :icon="['fas', 'key']" size="3x"></font-awesome-icon>
            </div>
          </div>
        </div>
      </div>


      <div class="row wow fadeInUp" data-wow-offset="150">
        <div class="roadmap-col-box-right col-12 col-md-8 col-lg-4">
          <div class="row roadmap-box-row">
            <div class="roadmap-box-icon-right col-sm-4 col-md-3">
              <font-awesome-icon :icon="['fas', 'code']" size="3x"></font-awesome-icon>
            </div>
            <div class="roadmap-box-text-right col-12 col-sm-8 col-md-9">
              <h5 class="roadmap-box-title">Q1 2023</h5>
              <p>Beginning of the development of the platform for the tokenization of Classic and Super Cars</p>
            </div>
          </div>
        </div>

        <div class="roadmap-col-image-right col-md-4">
          <img src="./../assets/images/rm_2.png" alt="" class="roadmap-image-right">
        </div>

        <div class="roadmap-col-empty-right col-lg-4"></div>
      </div>



      <div class="row wow fadeInUp" data-wow-offset="150">
        <div class="roadmap-col-empty-left col-lg-4"></div>

        <div class="col-md-4">
          <img src="./../assets/images/rm_1.png" alt="" class="roadmap-image-left">
        </div>

        <div class="col-12 col-md-8 col-lg-4">
          <div class="row roadmap-box-row">
            <div class="roadmap-box-text-left col-12 col-sm-8 col-md-9">
              <h5 class="roadmap-box-title">Q3-Q4 2023</h5>
              <p>EDT Public Presale <br>
              DEXs and CEXs Exchange Listing <br>
              Unveiling of Tokenization platform</p>
            </div>

            <div class="roadmap-box-icon-left col-sm-4 col-md-3">
              <font-awesome-icon :icon="['fas', 'users']" size="3x"></font-awesome-icon>
            </div>
          </div>
        </div>
      </div>


      <div class="row wow fadeInUp" data-wow-offset="150">
        <div class="roadmap-col-box-right col-12 col-md-8 col-lg-4">
          <div class="row roadmap-box-row">
            <div class="roadmap-box-icon-right col-sm-4 col-md-3">
              <font-awesome-icon :icon="['fas', 'charging-station']" size="3x"></font-awesome-icon>
            </div>
            <div class="roadmap-box-text-right col-12 col-sm-8 col-md-9">
              <h5 class="roadmap-box-title">Q1-Q2 2024</h5>
              <p>Beginning of the development of EDTs App with D2S model</p>
            </div>
          </div>
        </div>

        <div class="roadmap-col-image-right col-md-4">
          <img src="./../assets/images/rm_2.png" alt="" class="roadmap-image-right">
        </div>

        <div class="roadmap-col-empty-right col-lg-4"></div>
      </div>

<!--      <div class="row wow fadeInUp">-->
<!--        <div class="roadmap-col-empty-left col-lg-4"></div>-->

<!--        <div class="col-md-4">-->
<!--          <img src="./../assets/images/rm_1.png" alt="" class="roadmap-image-left">-->
<!--        </div>-->

<!--        <div class="col-12 col-md-8 col-lg-4">-->
<!--          <div class="row roadmap-box-row">-->
<!--            <div class="roadmap-box-text-left col-12 col-sm-8 col-md-9">-->
<!--              <h5 class="roadmap-box-title">Q2-Q3 2023</h5>-->
<!--              <p>Public Sale – Exchange Quotation-->
<!--                Start Development Platform Classic cars Tokenization</p>-->
<!--            </div>-->

<!--            <div class="roadmap-box-icon-left col-sm-4 col-md-3">-->
<!--              <font-awesome-icon :icon="['fas', 'envelope']" size="3x"></font-awesome-icon>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="row wow fadeInUp">-->
<!--        <div class="roadmap-col-box-right col-12 col-md-8 col-lg-4">-->
<!--          <div class="row roadmap-box-row">-->
<!--            <div class="roadmap-box-icon-right col-sm-4 col-md-3">-->
<!--              <font-awesome-icon :icon="['fas', 'phone-alt']" size="3x"></font-awesome-icon>-->
<!--            </div>-->
<!--            <div class="roadmap-box-text-right col-12 col-sm-8 col-md-9">-->
<!--              <h5 class="roadmap-box-title">Q4 2023</h5>-->
<!--              <p>Start EV Platform</p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="roadmap-col-image-right col-md-4">-->
<!--          <img src="./../assets/images/rm_2.png" alt="" class="roadmap-image-right">-->
<!--        </div>-->

<!--        <div class="roadmap-col-empty-right col-lg-4"></div>-->
<!--      </div>-->

    </div>
  </section>
</template>

<script>
    export default {
      name: "road-map"
    }
</script>

<style scoped>

</style>
