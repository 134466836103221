<template>
  <section class="cta-two" id="scars">
    <img src="./../assets/images/shapes/cta-2-shape-1.png" alt="" class="cta-two__shape-1">
<!--    <img src="/assets/images/shapes/cta-2-shape-2.png" alt="" class="cta-two__shape-2">-->
    <div class="container">
<!--      <img src="/assets/images/feature_2_shape.png" alt="" width="140px" class="cta-two__shape-3">-->

      <div class="row">
        <div class="col-12">
          <div class="cta-two__content">
            <div class="block-title text-left">
              <span class="block-title__bubbles"></span>
<!--              <p>Super Cars</p>-->
              <h3 class="text-white" style="font-size: 30px">Super Cars</h3>
            </div>
<!--            <p>Realize your dreams of owning a work of art! Advanced distribution and tokenization systems, also based on the Blockchain technology, will be realized to let you own portions of a super car!</p>-->
<!--            <a href="#" class="thm-btn cta-two__btn">Discover More</a>-->
          </div>
        </div>

        <div class="col-10 m-auto">
          <img src="./../assets/images/resources/section2_supercars.png" alt="" class="cta-two__moc" style="width: 100%;">
        </div>

        <div class="col-12 m-auto text-center">
          <h3 class="text-white text-center" style="font-size: 26px">Realize your dreams of owning a work of art!</h3>

          <div class="row mt-5">
            <div class="col-md-4">
              <div class="p-3 d-flex" style="border: 1px solid #08b2ab; border-radius: 12px; height: 100%;">
                <h5 class="m-auto" style="color: #08b2ab; line-height: 1.5">Innovative systems for the tokenization and distribution of portions of super cars, based on the Blockchain technology</h5>
              </div>
            </div>

            <div class="col-md-4">
              <div class="p-3 d-flex" style="border: 1px solid #08b2ab; border-radius: 12px; height: 100%">
              <h5 class="m-auto" style="color: #08b2ab; line-height: 1.5">Shared revenue streams for tokenized super cars, coming from exhibitions,renting and re-selling operations</h5>
              </div>
            </div>

            <div class="col-md-4">
              <div class="p-3 d-flex" style="border: 1px solid #08b2ab; border-radius: 12px; height: 100%">
              <h5 class="m-auto" style="color: #08b2ab; line-height: 1.5">Be ahead of the curve: get early reserved access to our tokenized super cars and never miss an opportunity.</h5>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
    export default {
      name: "FeaturesTwo"
    }
</script>

<style scoped>

</style>
