<template>
  <section class="my-5 parallax">
    <div class="container">

        <div class="row">
            <div class="block-title text-center col-12">
                <span class="block-title__bubbles"></span>
                <h3>Paddock Pass NFT</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-9 m-auto">
                <div class="d-none d-md-flex row vip-row">
                    <div class="col-4 vip-col"><p class="m-0 font-weight-bold text-dark">Paddock Pass Tier</p></div>
                    <div class="col-8 vip-col"><p class="m-0 font-weight-bold text-dark">Privileges</p></div>
                </div>
                <div class="row vip-row">
                    <div class="col-12 col-md-4 vip-col">
                        <div class="row d-block">
                            <div class="col-12">
                                <font-awesome-icon :icon="['fas', 'gem']" style="color: #016dff" size="2x"></font-awesome-icon>
                            </div>
                            <div class="col-12">
                                <p class="m-0">Diamond</p>
                            </div>
                        </div>
                    </div>
<!--                    <div class="col-12 col-md-5 vip-col">-->
<!--                        <p class="d-flex d-md-none m-0 font-weight-bold text-dark">Distribution Strategy</p>-->
<!--                        <p class="m-0">Airdropped to private sale participants who buy at least €20,000 worth of EDT</p>-->
<!--                    </div>-->
                    <div class="col-12 col-md-8 vip-col">
                        <div class="row d-block">
                            <p class="d-block d-md-none m-0 font-weight-bold text-dark">Privileges</p>
                            <li>0% transfer fees rate</li>
                            <li>Preemption advantages on future projects</li>
                            <li>50% higher airdrop quantity</li>
                            <li>15% higher success rate in lotteries</li>
                        </div>
                    </div>
                </div>
                <div class="row vip-row">
                    <div class="col-12 col-md-4 vip-col">
                        <div class="row d-block">
                            <div class="col-12">
                                <font-awesome-icon :icon="['fas', 'medal']" style="color: gold" size="2x"></font-awesome-icon>
                            </div>
                            <div class="col-12">
                                <p class="m-0">Gold</p>
                            </div>
                        </div>
                    </div>
<!--                    <div class="col-12 col-md-5 vip-col">-->
<!--                        <p class="d-flex d-md-none m-0 font-weight-bold text-dark">Distribution Strategy</p>-->
<!--                        <p class="m-0">-->
<!--                            Airdropped to private sale participants who buy at least-->
<!--                            €5,000 but less than €20,000 worth of EDT or to presale participants who buy at least-->
<!--                            €20,000 worth of EDT. Obtainable after the presale, buying a ticket-->
<!--                        </p>-->
<!--                    </div>-->
                    <div class="col-12 col-md-8 vip-col">
                        <div class="row d-block">
                            <p class="d-block d-md-none m-0 font-weight-bold text-dark">Privileges</p>
                            <li>0.5% transfer fees rate</li>
                            <li>30% higher airdrop quantity</li>
                            <li>5% higher success rate in lotteries</li>
                        </div>
                    </div>
                </div>
                <div class="row vip-row">
                    <div class="col-12 col-md-4 vip-col">
                        <div class="row d-block">
                            <div class="col-12">
                                <font-awesome-icon :icon="['fas', 'medal']" style="color: silver" size="2x"></font-awesome-icon>
                            </div>
                            <div class="col-12">
                                <p class="m-0">Silver</p>
                            </div>
                        </div>
                    </div>
<!--                    <div class="col-12 col-md-5 vip-col">-->
<!--                        <p class="d-flex d-md-none m-0 font-weight-bold text-dark">Distribution Strategy</p>-->
<!--                        <p class="m-0">Airdropped to presale participants who buy at least-->
<!--                            €5,000 but less than €20,000 worth of EDT. Obtainable after the presale, buying a ticket-->
<!--                        </p>-->
<!--                    </div>-->
                    <div class="col-12 col-md-8 vip-col">
                        <div class="row d-block">
                            <p class="d-block d-md-none m-0 font-weight-bold text-dark">Privileges</p>
                            <li>1% transfer fees rate</li>
                            <li>20% higher airdrop quantity</li>
                        </div>
                    </div>
                </div>
                <div class="row vip-row">
                    <div class="col-12 col-md-4 vip-col">
                        <div class="row d-block">
                            <div class="col-12">
                                <font-awesome-icon :icon="['fas', 'medal']" style="color: peru" size="2x"></font-awesome-icon>
                            </div>
                            <div class="col-12">
                                <p class="m-0">Bronze</p>
                            </div>
                        </div>
                    </div>
<!--                    <div class="col-12 col-md-5 vip-col">-->
<!--                        <p class="d-flex d-md-none m-0 font-weight-bold text-dark">Distribution Strategy</p>-->
<!--                        <p class="m-0">Obtainable after the presale, buying a ticket</p>-->
<!--                    </div>-->
                    <div class="col-12 col-md-8 vip-col">
                        <div class="row d-block">
                            <p class="d-block d-md-none m-0 font-weight-bold text-dark">Privileges</p>
                            <li>1.5% transfer fees rate</li>
                            <li>10% higher airdrop quantity</li>
                        </div>
                    </div>
                </div>
            </div>
        </div>




    </div>
  </section>
</template>

<script>
    export default {
      name: "vip-status"
    }
</script>

<style scoped>
 .vip-row {
     text-align: center;
 }

    .vip-col {
        padding: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .vip-row:not(:last-child) {
        border-bottom: 1px solid #085e57;
    }

    .vip-col:not(:last-child) {
        border-right: 1px solid #085e57;
    }

    @media(max-width: 767px) {
        .vip-col:not(:last-child) {
            border-right: none;
        }
    }

    p {
        color: #0b0b0b;
    }

    li {
        color: #000000;
    }

 .parallax {
     background: linear-gradient(to bottom, rgba(255,255,255,0) 10%,
     rgba(255,255,255,1)), linear-gradient(to top, rgba(255,255,255,0) 10%,
     rgba(255,255,255,1)), url('./../assets/images/background/diamond-bg.png') no-repeat right fixed;
     min-height: 500px;
     background-size: cover;
 }
</style>
