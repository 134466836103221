<template>
  <div>
    <BannerSection />
    <ServicesSection />
    <FeaturesOne />
    <FeaturesTwo />
    <FeaturesThree />
    <TokenomicsSection/>
    <vip-status></vip-status>
    <TeamSection />
    <RoadMap />
<!--    <Video />-->
<!--    <Counter /> -->
<!--    <Pricing /> -->
<!--    <Screenshots />-->
<!--    <Testimonial />-->
    <Brands />
    <Blog/>
<!--    <Faq />-->

    <CallToAction />
<!--    <modal-news v-show="showModal" @close-modal="showModal=false"></modal-news>-->
  </div>
</template>

<script>
  import BannerSection from "src/components/Banner.vue";
  import ServicesSection from "src/components/Service.vue";
  import FeaturesOne from "src/components/FeaturesOne.vue";
  import FeaturesTwo from "src/components/FeaturesTwo.vue";
  // import Video from "src/components/Video.vue";
  // import Counter from "src/components/Counter.vue";
  // import Pricing from "src/components/Pricing.vue";
  // import Screenshots from "src/components/Screenshots.vue";
  // import Testimonial from "src/components/Testimonial.vue";
   import Brands from "src/components/Brands.vue";
  // import Faq from "src/components/Faq.vue";
  import CallToAction from "src/components/CallToAction.vue";
  import FeaturesThree from "src/components/FeaturesThree";
  import TokenomicsSection from "src/components/Tokenomics";
  import RoadMap from "src/components/Roadmap";
  import TeamSection from "src/components/Team";
  import VipStatus from "src/components/VipStatus";
  // import ModalNews from "src/components/ModalNews";
  import Blog from "src/components/Blog";


export default {
  name: "Home-page",
  components: {
    VipStatus,
    TeamSection,
    BannerSection,
    ServicesSection,
    FeaturesOne,
    FeaturesTwo,
    FeaturesThree,
    // Video,
    // Counter,
    // Pricing,
    // Screenshots,
    // Testimonial,
     Brands,
    // Faq,
    CallToAction,
    TokenomicsSection,
    RoadMap,
    // ModalNews
      Blog,
  },
  data() {
      return {
          showModal: true,
      }
  },
}
</script>

<style>

</style>
