<template>
  <section class="banner-one banner-background-image" id="banner">

<!--    <img src="/assets/images/shapes/banner-shapes-1-1.png" alt="" class="banner-one__shape-1">-->
<!--    <img src="/assets/images/shapes/banner-shapes-1-2.png" alt="" class="banner-one__shape-2">-->

<!--    <img src="/assets/images/shapes/banner-shapes-1-4.png" alt="" class="banner-one__shape-4">-->
<!--    <img src="/assets/images/shapes/banner-shapes-1-6.png" alt="" class="banner-one__shape-6">-->
    <img src="./../assets/images/shapes/shape-blockchain.png" alt="" class="banner-one__shape-5" style="width: 90px; height: auto">
<!--    <img src="/assets/images/shapes/banner-shapes-1-7.png" alt="" class="banner-one__shape-7">-->


    <div class="container">
      <img src="./../assets/images/shapes/banner-shapes-1-3.png" alt="" class="banner-one__shape-moc-1">

      <div class="row">
        <div class="col-12 text-center">
          <!-- countdown -->
<!--          <div id="div_countdown" v-if="!countdownTerminated">-->
<!--            <h3 style="margin-bottom: -15px" class="text-white">Private sale is live! Public presale starts in</h3>-->
<!--            <div class="countdown">-->
<!--              <span id="days">{{countdown.days}}</span> .-->
<!--              <span id="hours">{{countdown.hours}}</span> :-->
<!--              <span id="minutes">{{countdown.minutes}}</span> :-->
<!--              <span id="seconds">{{countdown.seconds}}</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          &lt;!&ndash; end countdown &ndash;&gt;-->
<!--          <div v-else style="padding-top: 51px; padding-bottom: 51px" class="wow zoomIn">-->
<!--            <h2 class="text-white">Public presale is live!</h2>-->
<!--          </div>-->

<!--          <div style="padding-top: 51px; padding-bottom: 51px" class="wow zoomIn">-->
<!--            <h2 class="text-white">Public presale is closed!</h2>-->
<!--          </div>-->
        </div>

        <div class="col-md-8 col-lg-7">
          <div class="banner-one__content">
            <p class="banner-one__tag-line">Welcome to {{ name }} <a :href="register_url">join now</a></p>
            <h3 style="font-size: calc(14px + 1.5vw)">Bringing innovation in the automotive industry</h3>
            <p>Be part of the next revolution!
              Electric Vehicles, Smart charging stations, NFTs and tokenized Super & Classic cars under one umbrella: <b>EDriveToken</b></p>
<!--            <a href="#contact" data-target="#contact" class="thm-btn banner-one__btn scroll-to-target">Join Now</a>-->
<!--            <a href="/files/whitepaper.pdf" target="_blank" class="thm-btn banner-one__btn ml-2">WhitePaper</a>-->
<!--            <a :href="wpLink" target="_blank" class="thm-btn banner-one__btn ml-2">WhitePaper</a>-->
            <router-link to="/whitepaper" class="thm-btn banner-one__btn ml-2 px-2 px-sm-4 px-lg-5">WhitePaper</router-link>
            <a href="https://github.com/interfinetwork/project-delivery-data/blob/main/EDrive/EDriveToken_AuditReport_InterFi.pdf" target="_blank" class="thm-btn banner-one__btn ml-2 px-2 px-sm-4 px-lg-5">Security Audit</a>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSd9MYsMv1Scl4uJmYGYnsH0vsUm5vDCh0cMZ-mHKQTnwYe1ug/viewform" target="_blank" class="thm-btn banner-one__btn ml-2 px-2 px-sm-4 px-lg-5">EDTeam Ambassador</a>
            <a href="https://www.edrivetoken-projects.io/" target="_blank" class="thm-btn banner-one__btn ml-2 px-2 px-sm-4 px-lg-5">Showroom</a>
          </div>
        </div>
      </div>

      <img src="./../assets/images/resources/banner-electric-car.png" alt="" class="banner-one__moc">

    </div>
  </section>
</template>

<script>
  import axios from "axios";

    export default {
      name: "Banner-section",
      data() {
          return {
              name: process.env.VUE_APP_NAME,
              register_url: process.env.VUE_APP_PLATFORM_BASE_URL +  process.env.VUE_APP_PLATFORM_REGISTRATION_URL + (this.$route.query.ref ? '?ref=' + this.$route.query.ref : ""),
              // wpLink: require("/src/assets/files/whitepaper.pdf"),
              countdownInterval: null,
              countdown: {
                  days: "00",
                  hours: "00",
                  minutes: "00",
                  seconds: "00"
              },
              countdownTerminated: false
          }
      },
      methods: {
          initCountDown(countDate, serverTimeZoneInSeconds)
          {
              // Set the date we're counting down to
              let countDownDate = new Date(countDate).getTime();

              // Update the count down every 1 second
              this.countdownInterval = setInterval(
                  function ()
                  {
                      // Get today date and time
                      let now = new Date();

                      let nowUtc = now.getTime() + (now.getTimezoneOffset() * 60000);

                      // set new Date object
                      let new_date = new Date(nowUtc + (serverTimeZoneInSeconds * 1000));

                      // Find the distance between now an the count down date
                      let distance = countDownDate - new_date;

                      // Time calculations for days, hours, minutes and seconds
                      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
                      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

                      if(days < 10)
                          days = '0' + days;
                      if(hours < 10)
                          hours = '0' + hours;
                      if(minutes < 10)
                          minutes = '0' + minutes;
                      if(seconds < 10)
                          seconds = '0' + seconds;

                      // Display the result
                      this.countdown.days = days;
                      this.countdown.hours = hours;
                      this.countdown.minutes = minutes;
                      this.countdown.seconds = seconds;

                      // count down finished
                      if (distance < 0)
                      {
                          clearInterval(this.countdownInterval);
                          this.countdownTerminated = true;
                      }
                  }.bind(this), 1000);
          }
      },
      mounted() {
        this.initCountDown("2022/06/15 00:00", 0);
      },
    }
</script>

<style scoped>
  .banner-background-image {
    background-image: url('./../assets/images/background/banner-bg.jpg');
  }

  @import '../assets/css/countdown.css';
</style>
