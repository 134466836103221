<template>
  <section class="cta-one" id="tokenomics">
    <img src="./../assets/images/shapes/pricing-shape-1.png" alt="" class="funfact-one__shape-1">
    <img src="./../assets/images/shapes/tokenomics-shape.png" alt="" class="decoration_shape_top_right" style="width: 140px">
    <div class="container">

      <div class="row">
        <div class="col-12">

            <div class="block-title text-center">
              <span class="block-title__bubbles"></span>
              <h3 style="font-size: 30px">Tokenomics</h3>
            </div>

            <token-data></token-data>

          <section>
              <div class="row">
                <div class="col-12">
                  <img src="./../assets/images/resources/tokenomics.png" alt="" style="width: 100%">
                </div>
              </div>

          </section>

            <div class="cta-three__box-wrap row">

<!--              <div class="tokenomics_box col-lg-6">-->
<!--                <div class="tokenomics_icon">-->
<!--                    <font-awesome-icon :icon="['fas', 'trophy']"></font-awesome-icon>-->
<!--                </div>-->
<!--                <div class="cta-three__box-content text-justify">-->
<!--                  <h3>BNB rewards</h3>-->
<!--                  <p>Each transfer will be charged a 6% fee in EDT, that is swapped for BNB, and added to a reserve. Such a reserve is used to <strong>reward EDT holders</strong>, according to their held % of the circulating supply.</p>-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="tokenomics_box col-lg-6 ">-->
<!--                <div class="tokenomics_icon">-->
<!--                    <font-awesome-icon :icon="['fas', 'hand-holding-water']"></font-awesome-icon>-->
<!--                </div>-->
<!--                <div class="cta-three__box-content text-justify">-->
<!--                  <h3>Exclusive Airdrops</h3>-->
<!--                  <p>The projects related to EVs and Smart Mobility, Super and Classic Cars will be accompanied by their own token. A portion of these project tokens will be allocated for <strong>airdrop initiatives reserved to EDT holders</strong>. </p>-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="tokenomics_box col-lg-6 ">-->
<!--                <div class="tokenomics_icon">-->
<!--                    <font-awesome-icon :icon="['fas', 'gifts']"></font-awesome-icon>-->
<!--                </div>-->
<!--                <div class="cta-three__box-content text-justify">-->
<!--                    <h3>Prizes and Bounties</h3>-->
<!--                  <p>Several marketing initiatives will be launched  to promote the adoption of EDT. A portion of the tokens will be reserved for <strong>random draws, prizes and bounties</strong> for supporters and active community members.</p>-->
<!--                </div>-->
<!--              </div>-->

<!--                <div class="tokenomics_box col-lg-6 ">-->
<!--                    <div class="tokenomics_icon">-->
<!--                        <font-awesome-icon :icon="['fas', 'star']"></font-awesome-icon>-->
<!--                    </div>-->
<!--                    <div class="cta-three__box-content text-justify">-->
<!--                        <h3>VIP Status</h3>-->
<!--                        <p>Private Sale and Presale participants will obtain an exclusive NFT granting a VIP Status for free.-->
<!--                            <font-awesome-icon style="color: gold" :icon="['fas', 'star']"></font-awesome-icon>-->
<!--                            VIP members will receive exclusive advantages!-->
<!--                        </p>-->
<!--                    </div>-->
<!--                </div>-->

<!--                <div class="tokenomics_box col-lg-6 ">-->
<!--                    <div class="tokenomics_icon">-->
<!--                        <font-awesome-icon :icon="['fas', 'lock']"></font-awesome-icon>-->
<!--                    </div>-->
<!--                    <div class="cta-three__box-content text-justify">-->
<!--                        <h3>Locked Liquidity and Team tokens</h3>-->
<!--                        <p>The liquidity put on DEXs and the tokens for team members will be locked for a <strong>minimum of 12 months</strong>, through a custom, audited smart contract.-->

<!--                            This is an additional guarantee on the commitment of the team!</p>-->
<!--                    </div>-->
<!--                </div>-->

<!--                <div class="tokenomics_box col-lg-6">-->
<!--                    <div class="tokenomics_icon">-->
<!--                        <font-awesome-icon :icon="['fas', 'shield-alt']"></font-awesome-icon>-->
<!--                    </div>-->
<!--                    <div class="cta-three__box-content text-justify">-->
<!--                        <h3>Audited Secure smart contract</h3>-->
<!--                        <p>The EDT smart contracts will be <strong>audited by Interfi.Network</strong>, to check against security issues, including arithmetic overflows, reentrancy attacks, sandwich attacks, faults in business logic or in the economic model.</p>-->
<!--                    </div>-->
<!--                </div>-->


              <div class="tokenomics_box col-lg-6">
                <div class="tokenomics_icon">
                  <font-awesome-icon :icon="['fas', 'trophy']"></font-awesome-icon>
                </div>
                <div class="cta-three__box-content text-justify">
                  <h3>WBNB Rewards</h3>
                  <p>Just as trophies are a token of appreciation given by a racing federation in recognition of a pilot’s talent, we at EDriveToken want to reward each holder of EDT for their commitment. We won’t be giving you trophies, but something better! Each EDT transaction will be charged a 2% transaction fee. This amount will be swapped into WBNBs and made claimable to all holders according to the % of the total supply in possession!</p>
                </div>
              </div>

              <div class="tokenomics_box col-lg-6 ">
                <div class="tokenomics_icon">
                  <font-awesome-icon :icon="['fas', 'burn']"></font-awesome-icon>
                </div>
                <div class="cta-three__box-content text-justify">
                  <h3>Burn</h3>
                  <p>While you enjoy our rewards, we will still be BURNING rubber on the track to always be in pole position! The % of transaction fees going to EDTs wallet will be used to repurchase EDTs on the market and 50% will be burned to make our token deflationary. The remaining 50% will be allocated back to specific areas within our the ecosystem fund, such as: Community rewards; Staking and Lotteries.</p>
                </div>
              </div>

              <div class="tokenomics_box col-lg-6 ">
                <div class="tokenomics_icon">
                  <font-awesome-icon :icon="['fas', 'lock']"></font-awesome-icon>
                </div>
                <div class="cta-three__box-content text-justify">
                  <h3>Trust Fund</h3>
                  <p>Innovation is one of our vision’s pillars. We are in this industry to innovate and be unorthodox. We understand that for innovation to truly set in, trust is an important factor; just like every pilot trust its team of mechanics. As our stakeholders, your trust is what is most important to us, therefore let us take be your trusted team of mechanics and lead by example: this fund contains 10% of our total supply, unlocked, and we pledge to you that those tokens will never be used. We will renovate this pledge to you every year for a minimum of 10 years!</p>
                </div>
              </div>

              <div class="tokenomics_box col-lg-6 ">
                <div class="tokenomics_icon">
                  <font-awesome-icon :icon="['fas', 'users']"></font-awesome-icon>
                </div>
                <div class="cta-three__box-content text-justify">
                  <h3>Team Tokens</h3>
                  <p>As a further statement that the team at EDT is fully committed to the project and to deliver on its vision, team tokens will be subject to a 15 months long vesting! Feel free to race and explore our ecosystem far and wide, your trusted mechanics will always be here should you need a pit-stop.</p>
                </div>
              </div>

              <div class="tokenomics_box col-lg-6 mx-auto">
                <div class="tokenomics_icon">
                  <font-awesome-icon :icon="['fas', 'shield-alt']"></font-awesome-icon>
                </div>
                <div class="cta-three__box-content text-justify">
                  <h3>Audit</h3>
                  <p>To make sure our ecosystem is as amazing and as secure as possible we have asked our chiefs track marshals at InterFi.Network to audit our smart contract. After inspecting each single inch of concrete, the have given us the green light!  Feel free to check our astonishing results!</p>
                </div>
              </div>

            </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
    import TokenData from "src/components/TokenData";
    export default {
      name: "tokenomics-section",
      components: {
          TokenData
      }
    }
</script>

<style scoped>

</style>
