<template>
  <section class="page-header pageHeaderBackground">
    <div class="container">
      <h2>{{ title }}</h2>
    </div>
  </section>
</template>

<script>
    export default {
      name: "PageHeader",
      props: {
        title: {
          type: String
        }
      }
    }
</script>

<style scoped>
 .pageHeaderBackground {
   background-image: url('./../assets/images/background/banner-bg.jpg');
 }
</style>
