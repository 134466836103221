// import VnudButton from "./components/Button.vue";
// import { ElInput, ElTooltip } from "element-plus";

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(app) {
    // app.component("n-button", VnudButton);
    // app.component(ElInput.name, ElInput);
    // app.use(ElTooltip);
  },
};

export default GlobalComponents;
