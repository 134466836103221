<template>
  <div class="page-wrapper">
    <PageHeader :title="title" />
    <div class="row">

      <div class="col-12 p-5 text-center">
        <h1><font-awesome-icon :icon="['fas', 'ban']"></font-awesome-icon> {{title}} <font-awesome-icon :icon="['fas', 'ban']"></font-awesome-icon></h1>
        <h5>{{ $t('error.page_not_found') }}</h5>

        <router-link to="/" class="thm-btn cta-three__btn"><font-awesome-icon :icon="['fas', 'home']"></font-awesome-icon> Back to Home</router-link>
      </div>

    </div>
    <router-view></router-view>
  </div>
</template>
<script>
  import PageHeader from "src/components/PageHeader.vue";
  export default {
    components: {
      PageHeader
    },
    data(){
      return {
        title: "Page Not Found"
      }
    }
  }
</script>
