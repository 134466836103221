<template>
  <section class="cta-one">
    <div class="container">
      <div class="cta-one__circle-1"></div>
      <div class="cta-one__circle-2"></div>
      <div class="cta-one__circle-3"></div>
      <div class="cta-one__content text-center">
        <h3>Experience Endless Possibilities in the Automotive Industry</h3>
<!--        <p></p>-->
        <a :href="register_url" class="thm-btn cta-one__btn mx-1">Fire up your engine!</a>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSd9MYsMv1Scl4uJmYGYnsH0vsUm5vDCh0cMZ-mHKQTnwYe1ug/viewform"
           target="_blank"
           class="thm-btn cta-one__btn mx-1">EDTeam Ambassador
        </a>
      </div>
    </div>
  </section>
</template>

<script>
    export default {
      name: "CallToAction",
      data() {
          return {
              name: process.env.VUE_APP_NAME,
              register_url: process.env.VUE_APP_PLATFORM_BASE_URL +  process.env.VUE_APP_PLATFORM_REGISTRATION_URL + (this.$route.query.ref ? '?ref=' + this.$route.query.ref : ""),
              // wpLink: require("/src/assets/files/whitepaper.pdf"),
          }
      }
    }
</script>

<style scoped>

</style>
