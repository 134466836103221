<template>
  <section class="cta-three cta-three-background-image" id="hcars">

    <img src="./../assets/images/shapes/cta-1-shape-2.png" alt="" class="cta-three__shape-2" style="margin-top: -200px;">
<!--    <img src="/assets/images/shapes/cta-1-shape-3.png" alt="" class="decoration_shape_top_right">-->
    <div class="container">
<!--      <img src="/assets/images/shapes/cta-1-shape-1.png" alt="" class="cta-three__shape-1">-->

      <div class="row justify-content-end">
        <div class="col-lg-6">
          <img src="./../assets/images/resources/section3_classic_car.png" alt="" class="cta-three__moc">
          <p class="text-black-50" style="font-size: small; margin-top: -10px;">Picture taken from <a href="https://www.thedrive.com/news/a-142m-mercedes-benz-might-now-be-the-worlds-most-expensive-car" target="_blank" style="color: #0c5460">here</a></p>
        </div>
        <div class="col-lg-6">
          <div class="cta-three__content">
            <div class="block-title text-left">
              <span class="block-title__bubbles"></span>
<!--              <p>EV and Smart Mobility</p>-->
              <h3 style="font-size: 30px">Classic Cars</h3>
            </div>

            <div class="cta-three__box-wrap">

              <div class="cta-three__box">
                <div class="cta-three__box-icon">
                  <font-awesome-icon :icon="['fas', 'heart']" size="3x"></font-awesome-icon>
                </div>
                <div class="cta-three__box-content">
                  <h3>For passionate people</h3>
                  <p>Collect pieces of history in the form of tokenized classic cars!</p>
                </div>
              </div>

              <div class="cta-three__box">
                <div class="cta-three__box-icon">
                  <font-awesome-icon :icon="['fas', 'piggy-bank']" size="3x"></font-awesome-icon>
                </div>
                <div class="cta-three__box-content">
                  <h3>Safe haven assets</h3>
                  <p>In the current inflationary era, classic cars are perceived as safe haven assets, generating revenues from exhibitions, renting and re-selling operations</p>
                </div>
              </div>

<!--              <div class="cta-three__box">-->
<!--                <div class="cta-three__box-icon">-->
<!--                  <i class="zimed-icon-training"></i>-->
<!--                </div>-->
<!--                <div class="cta-three__box-content">-->
<!--                  <h3>Benefits for EDT holders</h3>-->
<!--                  <p>Early reserved access to tokenized classic cars, free bounties</p>-->
<!--                </div>-->
<!--              </div>-->

            </div>

<!--            <a href="#" class="thm-btn cta-three__btn">Discover More</a>-->

          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
    export default {
      name: "FeaturesThree"
    }
</script>

<style scoped>
 .cta-three-background-image {
   background-image: url('./../assets/images/background/section_3_bg.jpg');
   background-size: contain;
 }
</style>
