<template>
  <div>
    <footer class="site-footer" id="footer">
<!--      <img src="/assets/images/shapes/footer-shape-1.png" class="site-footer__shape-1" alt="">-->
<!--      <img src="/assets/images/shapes/footer-shape-2.png" class="site-footer__shape-2" alt="">-->
      <img src="./../assets/images/shapes/footer-shape-3.png" class="site-footer__shape-3" alt="">
      <div class="site-footer__upper">
        <div class="container">
          <div class="row">
            <div class="col-lg-4">
              <div class="footer-widget footer-widget__about text-center">
                <a class="footer-widget__logo" href="/"><img src="./../assets/images/logo.png" style="width:90px" alt=""/></a>
                <p>{{ name }} is a project of <br/>
                  <router-link to="/companyRegistration" class="text-white"><b>{{ company_name }}</b></router-link>
                </p>

                <p class="mt-1"> Company number: {{ company_number }} </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-5">

              <div class="contact-one__infos-single">
                <div class="contact-one__infos-icon">
                  <i class="zimed-icon-placeholder"></i>
                </div>
                <div class="contact-one__infos-content text-white">
                  <p style="font-size: 14px">{{ company_address_line1 }}<br/>{{ company_address_line2 }}</p>
                </div>
              </div>

              <div class="contact-one__infos-single">
                <div class="contact-one__infos-icon">
                  <i class="zimed-icon-message"></i>
                </div>
                <div class="contact-one__infos-content text-white">
                  <p style="font-size: 14px"><a :href="'mailto:' + email_address" rel="noopener noreferrer">{{ email_address }}</a></p>
                </div>
              </div>

            </div>

            <div class="col-md-6 col-lg-3">
              <div class="footer-widget footer-widget__links">
                <h3 class="footer-widget__title">Explore</h3>
                <div class="footer-widget__links-wrap">
                  <ul class="list-unstyled">
                    <li><router-link to="/privacy_policy">
                      <font-awesome-icon class="footer-link-icon" :icon="['fas', 'angle-double-right']"></font-awesome-icon>
                      Privacy Policy</router-link></li>
                    <li><router-link to="/cookie_policy">
                      <font-awesome-icon class="footer-link-icon" :icon="['fas', 'angle-double-right']"></font-awesome-icon>
                      Cookie Policy</router-link></li>
                    <li>
                      <router-link to="/terms">
                        <font-awesome-icon class="footer-link-icon" :icon="['fas', 'angle-double-right']"></font-awesome-icon>
                        Terms & Conditions
                      </router-link>
<!--                      <a href="javascript:void(0);" v-on:click="downloadTerms">-->
<!--                        <font-awesome-icon class="footer-link-icon" :icon="['fas', 'angle-double-right']"></font-awesome-icon>-->
<!--                        Terms & Conditions-->
<!--                      </a>-->
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="site-footer__bottom">
        <div class="container">
          <div class="inner-container">
            <div class="site-footer__social">
              <a :href="telegram" target="_blank">
                <font-awesome-icon :icon="['fab', 'telegram-plane']"></font-awesome-icon></a>
              <a :href="instagram" target="_blank">
                <font-awesome-icon :icon="['fab', 'instagram']"></font-awesome-icon></a>
              <a :href="facebook" target="_blank">
                <font-awesome-icon :icon="['fab', 'facebook-f']"></font-awesome-icon></a>
              <a :href="twitter" target="_blank">
                <img src="../assets/images/icons/x-logo-white.png" alt="" style="height: 1em;width: auto"/></a>
              <a :href="medium" target="_blank">
                <font-awesome-icon :icon="['fab', 'medium-m']"></font-awesome-icon></a>
              <a :href="youtube" target="_blank">
                <font-awesome-icon :icon="['fab', 'youtube']"></font-awesome-icon></a>
<!--              <a :href="reddit" target="_blank">-->
<!--                <font-awesome-icon :icon="['fab', 'reddit-alien']"></font-awesome-icon></a>-->
            </div>
            <p>© {{ company_name }} {{ company_year }} - {{year}}. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
    <a href="#" data-target="html" class="scroll-to-target scroll-to-top">
      <font-awesome-icon :icon="['fas', 'angle-up']"></font-awesome-icon>
    </a>

    <div class="side-menu__block">


      <div class="side-menu__block-overlay custom-cursor__overlay">
        <div class="cursor"></div>
        <div class="cursor-follower"></div>
      </div>
      <div class="side-menu__block-inner ">
        <div class="side-menu__top justify-content-end">

          <a href="#" class="side-menu__toggler side-menu__close-btn"><img src="./../assets/images/shapes/close-1-1.png" alt=""></a>
        </div>


        <nav class="mobile-nav__container">
          <!-- content is loading via js -->
        </nav>
        <div class="side-menu__sep"></div>
        <div class="side-menu__content">
          <p>{{ name }} is a project of <br> <b>{{ company_name }}</b></p>
          <p><a :href="'mailto:' + email_address">{{ email_address }}</a></p>
          <div class="side-menu__social">
            <a :href="telegram" target="_blank">
              <font-awesome-icon :icon="['fab', 'telegram-plane']"></font-awesome-icon></a>
            <a :href="instagram" target="_blank">
              <font-awesome-icon :icon="['fab', 'instagram']"></font-awesome-icon></a>
            <a :href="facebook" target="_blank">
              <font-awesome-icon :icon="['fab', 'facebook-f']"></font-awesome-icon></a>
            <a :href="twitter" target="_blank">
              <img src="../assets/images/icons/x-logo-white.png" alt="" style="height: 1em;width: auto"/></a>
            <a :href="medium" target="_blank">
              <font-awesome-icon :icon="['fab', 'medium-m']"></font-awesome-icon></a>
            <a :href="youtube" target="_blank">
              <font-awesome-icon :icon="['fab', 'youtube']"></font-awesome-icon></a>
<!--            <a :href="reddit" target="_blank">-->
<!--              <font-awesome-icon :icon="['fab', 'reddit-alien']"></font-awesome-icon></a>-->
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import axios from 'axios';

    export default {
      name: "footer-section",
      data() {
          return {
              name: process.env.VUE_APP_NAME,
              company_name: process.env.VUE_APP_COMPANY_NAME,
              company_address_line1: process.env.VUE_APP_COMPANY_ADDRESS_LINE_1,
              company_address_line2: process.env.VUE_APP_COMPANY_ADDRESS_LINE_2,
              company_number: process.env.VUE_APP_COMPANY_NUMBER,
              company_year: process.env.VUE_APP_COMPANY_YEAR,
              year: new Date().getFullYear(),
              email_address: process.env.VUE_APP_CONTACT_US_MAIL,
              instagram: process.env.VUE_APP_INSTAGRAM,
              facebook: process.env.VUE_APP_FACEBOOK,
              twitter: process.env.VUE_APP_TWITTER,
              medium: process.env.VUE_APP_MEDIUM,
              reddit: process.env.VUE_APP_REDDIT,
              youtube: process.env.VUE_APP_YOUTUBE,
              telegram: process.env.VUE_APP_TELEGRAM,
              // termsLink: require("/src/assets/files/termsAndConditions.pdf"),
          }
      },
      methods: {
        // downloadTerms() {
        //   axios.get(this.termsLink, {responseType: 'blob'})
        //     .then(response => {
        //       const blob = new Blob([response.data], {type: 'application/pdf'});
        //       const link = document.createElement('a');
        //       link.href = URL.createObjectURL(blob);
        //       link.download = "EDT_TermsAndConditions.pdf";
        //       link.click();
        //       URL.revokeObjectURL(link.href);
        //     }).catch(console.error);
        // }
      }
    }
</script>

<style scoped>

</style>
