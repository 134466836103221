<template>
  <div class="page-wrapper">
    <PageHeader :title="title" />
    <div class="row p-5">

      <div class="col-12 p-5 text-justify">
        <h4 class="font-weight-bold text-green">
          <font-awesome-icon :icon="['fas', 'angle-double-right']" size="lg"></font-awesome-icon> Definitions and legal references</h4>
        <p class="font-weight-bold mt-5 text-green">Personal Data (or Data)</p>
        <p>Any information that directly, indirectly, or in connection with other information —
          including a personal identification number — allows for the identification or identifiability
          of a natural person.</p>
        <br>
        <p class="font-weight-bold text-green">Data Subject</p>
        <p>The natural person to whom the Personal Data refers.</p>
        <br>
        <p class="font-weight-bold text-green">{{ productName }} (or this Application)</p>
        <p>The means by which the Personal Data of the User is collected and processed.</p>
        <br>
        <p class="font-weight-bold text-green">User</p>
        <p>The individual using {{ productName }} who, unless otherwise specified, coincides with the Data Subject.</p>
        <br>
        <p class="font-weight-bold text-green">Data Controller (or Owner)</p>
        <p>The natural or legal person, public authority, agency or other body which, alone or jointly with
          others, determines the purposes and means of the processing of Personal Data, including the
          security measures concerning the operation and use of {{ productName }}. The Data Controller, unless
          otherwise specified, is the Owner of {{ productName }}.</p>
        <br>
        <p class="font-weight-bold text-green">Data Processor (or Data Supervisor)</p>
        <p>The natural or legal person, public authority, agency or other body which processes Personal
          Data on behalf of the Controller, as described in this privacy policy.</p>
        <br>
        <p class="font-weight-bold text-green">Usage Data</p>
        <p>Information collected automatically through {{ productName }} (or third-party services employed in
          {{ companyName }}), which can include: the IP addresses or domain names of the computers utilized by
          the Users who use {{ productName }}, the URI addresses (Uniform Resource Identifier), the time of the
          request, the method utilized to submit the request to the server, the size of the file received
          in response, the numerical code indicating the status of the server's answer (successful outcome,
          error, etc.), the country of origin, the features of the browser and the operating system utilized
          by the User, the various time details per visit (e.g., the time spent on each page within the
          Application) and the details about the path followed within the Application with special reference
          to the sequence of pages visited, and other parameters about the device operating system and/or
          the User's IT environment.</p>
        <br>
        <p class="font-weight-bold text-green">Service</p>
        <p>The service provided by {{ companyName }} as described in the relative terms (if available) and on
          this site/application.</p>
        <br>
        <p class="font-weight-bold text-green">Cookies</p>
        <p>Small sets of data stored in the User's device.</p>
        <br>
        <p class="font-weight-bold text-green">United Kingdom (UK)</p>
        <p>Unless otherwise specified, all references made within this document to the United Kingdom include The
          United Kingdom of Great Britain and Northern Ireland.</p>
        <br>
        <p class="font-weight-bold text-green">European Union (or EU)</p>
        <p>Unless otherwise specified, all references made within this document to the European Union
          include all current member states to the European Union and the European Economic Area.</p>
        <br>
        <p class="font-weight-bold text-green">Legal information</p>
        <p>This privacy statement has been prepared based on provisions of multiple legislations,
          including UK GDPR and EU GDPR.</p>

        <br>
        <p>This privacy policy relates solely to {{ companyName }}, if not stated otherwise within this document.</p>
        <hr/>


        <h4 class="font-weight-bold text-green">
          <font-awesome-icon :icon="['fas', 'angle-double-right']" size="lg"></font-awesome-icon> Owner and Data Controller</h4>
        <p id="privacy_email" class="font-weight-bold">{{ companyName }}</p>
        <p>{{ companyAddress1 }} {{ companyAddress2 }}<br>
          Company number: {{companyNumber}}<br>
<!--          ICO Registration number: ZA730856<br>-->
          Privacy Officer contact email: <img src="../assets/images/privacy_email.png" alt="privacy officer contact" style="height: 25px"></p>


        <hr/>


        <h4 class="font-weight-bold text-green">
          <font-awesome-icon :icon="['fas', 'angle-double-right']" size="lg"></font-awesome-icon> Introduction</h4>
        <p>{{ companyName }} (“the Company”, “We”, “Our” or “Us”) is providing this Privacy Policy to explain what
          personal data We collect, how We use it, and your rights if you want to change how We use your
          personal data.
        </p>
        <p class="font-weight-bold text-green">Please read this Policy carefully!</p>
        <hr/>

        <h4 class="font-weight-bold text-green">
          <font-awesome-icon :icon="['fas', 'angle-double-right']" size="lg"></font-awesome-icon> Personal data you give Us</h4>
        <p>You may give Us personal data by:</p>
        <ul>
          <li>Registering to use edrivetoken.io;</li>
          <li>Filling in forms online;</li>
          <li>Corresponding with Us by phone, e-mail or Skype; or</li>
          <li>Subscribing to Our newsletter</li>
        </ul>
        <p>In the following, We report the detailed set of data We ask you, mandatorily or optionally, for each
          process you may be involved in.</p>
        <table class="table table-responsive table-condensed table-bordered">
          <thead class="thead-light">
          <tr>
            <th style="min-width: 130px">Process name</th>
            <th>Mandatory personal data</th>
            <th>Optional personal data</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Registration</td>
            <td>Name, e-mail address, password, cookie data, Usage data and account number (provided once data is deleted).</td>
            <td>Date of birth, address, Binance Smart Chain address, telephone number, Facebook page, Twitter page, LinkedIn page,
              company name, company address.</td>
          </tr>
          <tr>
            <td>Token Sale</td>
            <td>Binance Smart Chain address, Usage data, and cookies data; cryptocurrency address or IBAN details; identification documents (for example, national ID card, passport or driving licence), proof of residence documents (for example, utility bills or bank statements), any
              other documents you have provided for identification purposes (for example, proof of source of funds), and any other data (that may also include
              sensitive data) that can be extracted from such documents; a selfie confirming your identity and your identification document or the result of a liveness check;
              results of background AML checks</td>
            <td>None</td>
          </tr>
          <tr>
            <td>Support form services</td>
            <td>Telephone number or Skype ID, any (also sensitive) personal data that may be contained within the e-mail message,
              Usage data, and cookies data.
            </td>
            <td>None</td>
          </tr>
          <tr>
            <td>Contact form services</td>
            <td>Name, e-mail address, any (also sensitive) personal data that may be contained within the e-mail message,
              Usage data, and cookies data.
            </td>
            <td>None</td>
          </tr>
          <tr>
            <td>Invoice processing</td>
            <td>Name, address, VAT number or fiscal code, contact details (examples include but are not limited to:
              telephone number, mobile number, Skype details, e-mail address, website), IBAN details.
            </td>
            <td>None</td>
          </tr>
          <tr>
            <td>Consultant and Collaborator engagement</td>
            <td>Full name, ID card number or passport number, qualifications, signature and photograph.</td>
            <td>LinkedIn ID and personal website link</td>
          </tr>
          </tbody>
        </table>
        <hr/>

        <h4 class="font-weight-bold text-green">
          <font-awesome-icon :icon="['fas', 'angle-double-right']" size="lg"></font-awesome-icon> Personal data We collect from your device</h4>
        <p>We use Usage Data and Cookies to manage Our website, and to make sure that content from Our website is
          presented in the most effective way for you and your device. For more information about cookies, please
          refer to our Cookie Policies for our <router-link to="cookie_policy">landing page (www.edrivetoken.io)</router-link>
          and for the <a :href="platform_cookie_url">token sale platform (sale.edrivetoken.io)</a>.
          </p>

        <hr/>

        <h4 class="font-weight-bold text-green">
          <font-awesome-icon :icon="['fas', 'angle-double-right']" size="lg"></font-awesome-icon> Lawful basis for personal data processing</h4>

        <table class="table table-sm table-condensed table-bordered">
          <thead class="thead-light">
          <tr>
            <th class="px-3">Process name</th>
            <th class="px-3">Lawful basis for processing</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Registration</td>
            <td>Contract</td>
          </tr>
          <tr>
            <td>Token Sale</td>
            <td>Contract and Law (Includes criminal offence data. May include special category of data)</td>
          </tr>
          <tr>
            <td>Support form services</td>
            <td>Legitimate interest (May include special category of data)</td>
          </tr>
          <tr>
            <td>Contact form services</td>
            <td>Legitimate interest (May include special category of data)</td>
          </tr>
          <tr>
            <td>Invoice processing</td>
            <td>Law</td>
          </tr>
          <tr>
            <td>Consultant and Collaborator engagement</td>
            <td>Contract and Consent</td>
          </tr>
          <tr>
            <td>Marketing, statistics and preference cookies</td>
            <td>Consent</td>
          </tr>
          </tbody>
        </table>

        <hr/>

        <h4 class="font-weight-bold text-green">
          <font-awesome-icon :icon="['fas', 'angle-double-right']" size="lg"></font-awesome-icon> How long We keep your personal data?</h4>
        <p>Under England and Wales Law, We are required to keep your documents according to Our Data Retention Policy.
          After this period, your personal data will be irreversibly destroyed. Any personal data held by Us for
          service notifications will be kept by Us until such time that you notify Us that you no longer wish to receive
          this data.</p>
        <p>We will not retain your personal data for longer than is necessary to fulfil the purpose it is being processed
          for. To determine the appropriate retention period, We consider the amount, nature and sensitivity of the
          personal data, the purposes for which We process it and whether We can achieve those purposes through other
          means.</p>

        <p>Blockchain relies on a distributed ledger system that is decentralized and immutable. It is intended to be
          a permanent and a tamper-proof record that sits outside the control of any one governing authority.
          Information on the blockchain, including personal information of Data Subjects, cannot be modified or deleted.
          <br>
          The Company vows data protection transparency. As Data Controller, we are obliged to be transparent with
          Data Subjects about how we process their personal data. Thus, Data Subjects are informed that the Company
          must collect an Binance Smart Chain address to offer a service. Even though the Company deletes personal data as per
          Retention Policy, the Binance Smart Chain address and all the blockchain transactions linked to it are never deleted
          from the blockchain due to its immutability property. This is a fact that Data Subject must accept if s/he
          decides to participate in an open source, public blockchain.</p>

        <p>Should you require further information about Our retention periods, please contact Us using the provided
          <a href="#privacy_email">Privacy Officer contact email</a> outlining your requirements.</p>

        <hr/>

        <h4 class="font-weight-bold text-green">
          <font-awesome-icon :icon="['fas', 'angle-double-right']" size="lg"></font-awesome-icon> Do We share your personal data?</h4>
        <p>We share your personal data with third party suppliers to provide you with the best service. </p>

        <table class="table table-responsive table-condensed table-bordered">
          <thead class="thead-light">
          <tr>
            <th width="10%">3rd party</th>
            <th>Service</th>
            <th width="8%">Place</th>
            <th>Data</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Amazon Web Services, Inc.<br><a href="https://aws.amazon.com/compliance/data-privacy-faq/">Privacy Policy</a></td>
            <td>Hosting and backend service provided</td>
            <td>Germany</td>
            <td>All the data collected</td>
          </tr>
          <tr>
            <td>Sum And Substance Ltd<br><a href="https://sumsub.com/privacy-and-cookie-policy/">Privacy Policy</a></td>
            <td>Service to verify the user's identity</td>
            <td>United Kingdom</td>
            <td>Identification documents (for example, national ID card, passport or driving licence), proof of residence documents (for example, utility bills or bank statements), any
              other documents you have provided for identification purposes (for example, proof of source of funds), and any other data (that may also include
              sensitive data) that can be extracted from such documents; a selfie confirming your identity and your identification document or the result/video of a liveness check; results of background AML checks.
            </td>
          </tr>
          <tr>
            <td>Sendgrid Inc<br><a href="https://sendgrid.com/policies/privacy/">Privacy Policy</a></td>
            <td>Email address management and message sending service</td>
            <td>United States</td>
            <td>E-mail address, first name, any personal data that may be contained within the e-mail message</td>
          </tr>
          <tr>
            <td>Google Ireland Limited <br><a href="https://policies.google.com/privacy">Privacy Policy</a></td>
            <td>Google Analytics: track and examine the use of {{ productName }}; Google Fonts: typeface visualization
              service; Google Tag Manager: tag management service; Google Drive: save and manage backups</td>
            <td>Ireland</td>
            <td>Cookies, Usage Data</td>
          </tr>
          <tr>
            <td>Revolut Limited <br><a href="https://www.revolut.com/legal/privacy">Privacy Policy</a></td>
            <td>Banking service</td>
            <td>United Kingdom</td>
            <td>Name, address, IBAN details (only if the Data Subject participates via a bank transfer).</td>
          </tr>
          <tr>
            <td>Dropbox Inc. <br><a href="https://www.dropbox.com/privacy#privacy">Privacy Policy</a></td>
            <td>Save and manage backups</td>
            <td>United States</td>
            <td>Name, address, VAT number or fiscal code, contact details (examples include but are not limited to:
              telephone number, mobile number, Skype details, e-mail address, website), IBAN details.</td>
          </tr>
          <tr>
            <td>24 Tax and Consulting LTD <br><a href="https://ltd24ore.com/privacy-policy/">Privacy Policy</a></td>
            <td>Accounting</td>
            <td>United Kingdom</td>
            <td>Name, address, VAT number or fiscal code, contact details (examples include but are not limited to:
              telephone number, mobile number, Skype details, e-mail address, website), IBAN details.</td>
          </tr>
          <tr>
            <td>Cloudflare Inc. <br><a href="https://www.cloudflare.com/privacypolicy/">Privacy Policy</a></td>
            <td>Traffic optimization and distribution service</td>
            <td>United States</td>
            <td>Usage Data</td>
          </tr>
          <tr>
            <td>Microsoft Coorp. <br><a href="https://privacy.microsoft.com/it-it/privacystatement">Privacy Policy</a></td>
            <td>Skype: instant messaging service</td>
            <td>United States</td>
            <td>Any (also sensitive) personal data that may be contained within messages</td>
          </tr>
          <tr>
            <td>NameCheap Inc. <br><a href="https://www.namecheap.com/legal/general/privacy-policy/">Privacy Policy</a></td>
            <td>Email address management and message sending service</td>
            <td>United States</td>
            <td>E-mail address, any (also sensitive) personal data that may be contained within the e-mail message</td>
          </tr>
          <tr>
            <td>Smartsupp.com s.r.o. <br><a href="https://www.smartsupp.com/help/privacy/">Privacy Policy</a></td>
            <td>Chat service</td>
            <td>Czech Republic</td>
            <td>Name, E-mail address, Cookies, Usage Data</td>
          </tr>
          </tbody>
        </table>

        <br>
        <p>Moreover, the Company may share your personal data with:</p>
        <ul>
          <li>Other third parties to the extent necessary to: (i) comply with a government request, a court order or
            applicable law; (ii) prevent illegal uses of Our website, violations of Our website, its terms of use and Our
            policies; (iii) defend Ourselves against third party claims; and (iv) assist in fraud prevention or investigation.</li>
          <li>To any other third party for which you have provided Us with your consent.</li>
        </ul>
        <hr/>


        <h4 class="font-weight-bold text-green">
          <font-awesome-icon :icon="['fas', 'angle-double-right']" size="lg"></font-awesome-icon> How do we protect your data?</h4>
        <p>Our site is reviewed on a regular basis for security vulnerabilities in order to make your visit to our site as safe as possible.
          Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have
          special access rights to such systems, and are required to keep the information confidential. We implement a variety of security
          measures when a user places an order enters, submits, or accesses their information to maintain the safety of your personal information.
        </p>
        <hr/>


        <h4 class="font-weight-bold text-green">
          <font-awesome-icon :icon="['fas', 'angle-double-right']" size="lg"></font-awesome-icon> What are your rights?</h4>
        <p>You have the right to request:</p>
        <ul>
          <li>Access to your personal data;</li>
          <li>An electronic copy of your personal data (portability);</li>
          <li>Correction of your personal data if it is incomplete or inaccurate; or</li>
          <li>Deletion or restriction of your personal data in certain circumstances provided by applicable law.</li>
        </ul>

        <p>These rights are not absolute. Where We have obtained your consent for the processing of your personal data,
          you have the right to withdraw your consent at any time.</p>
        <p>If you would like to request a copy of your personal data or exercise any of your other rights, kindly
          contact Us using the provided <a href="#privacy_email">Privacy Officer contact email</a> </p>
        <p>If you have any questions related to UK & EU GDPR 2016/679 compliance or Data Protection, you can contact Us
          using the provided <a href="#privacy_email">Privacy Officer contact email</a>. If you still feel that your
          personal data has not been handled appropriately according to the Law, you have the right to lodge a
          complaint against the Company regarding data protection issues with the
          <a href="https://ico.org.uk/concerns/">Data Commissioner’s Office</a>.
        </p>
        <hr/>

        <h4 class="font-weight-bold text-green">
          <font-awesome-icon :icon="['fas', 'angle-double-right']" size="lg"></font-awesome-icon> Other local Data Protection Laws</h4>
        <p>We at {{ companyName }} take the data privacy of our customers and guests very seriously
          and are committed to abiding by the applicable Data Protection Laws.</p>
        <p>If you have any enquiry or complaint regarding how we handle your personal data or how we comply with your
          local applicable Data Protection Law, we welcome you to contact us using the provided
          <a href="#privacy_email">Privacy Officer contact email</a>.</p>
        <p>When submitting an enquiry or a complaint, your indication of the applicable Data Protection Law in the subject
          would assist us in attending to your complaint effectively.</p>
        <hr/>

        <h4 class="font-weight-bold text-green">
          <font-awesome-icon :icon="['fas', 'angle-double-right']" size="lg"></font-awesome-icon> Changes to this privacy policy</h4>
        <p>The Owner reserves the right to make changes to this privacy policy at any time by notifying its Users on
          this page and possibly within {{ productName }} and/or - as far as technically and legally feasible - sending a
          notice to Users via any contact data available to the Owner. It is strongly recommended to check this page
          often, referring to the date of the last update.</p>
        <p>Should the changes affect processing activities performed on the basis of the User’s consent, the Owner
          shall collect new consent from the User, where required.</p>

        <br><br>
        <p>Last update: {{ privacy_policy_last_update }}</p>

<!--        <router-link to="/" class="thm-btn cta-three__btn text-white"><font-awesome-icon :icon="['fas', 'home']"></font-awesome-icon> Back to Home</router-link>-->
      </div>

    </div>
    <router-view></router-view>
  </div>
</template>
<script>
  import PageHeader from "src/components/PageHeader.vue";
  export default {
    components: {
      PageHeader
    },
    data(){
      return {
        title: "Privacy Policy",
        productName: process.env.VUE_APP_NAME,
        companyName: process.env.VUE_APP_COMPANY_NAME,
        companyAddress1: process.env.VUE_APP_COMPANY_ADDRESS_LINE_1,
        companyAddress2: process.env.VUE_APP_COMPANY_ADDRESS_LINE_2,
        companyNumber: process.env.VUE_APP_COMPANY_NUMBER,
        privacy_policy_last_update: "2022-03-11",
        platform_cookie_url: process.env.VUE_APP_PLATFORM_BASE_URL +  process.env.VUE_APP_PLATFORM_COOKIE_POLICY_URL,
      }
    },
    mounted() {
      window.scrollTo(0, 0);
    }
  }
</script>

<style scoped>
  .text-green {
    color: #0c5460;
  }

  a {
    color: #0c5460;
    font-weight: bold;
  }
  a:hover {
    color: #0c95a1;
  }

  hr {
    margin-bottom: 60px;
  }

  p {
    font-size: 16px;
  }

  table {
    font-size: 16px;
  }

  li {
    font-size: 16px;
  }
</style>
