<template>
  <section class="cta-one" id="ev_sb">

    <img src="./../assets/images/shapes/cta-1-shape-2.png" alt="" class="cta-three__shape-2">
<!--    <img src="/assets/images/shapes/cta-1-shape-3.png" alt="" class="decoration_shape_top_right">-->
    <div class="container">
      <img src="./../assets/images/shapes/cta-1-shape-1.png" alt="" class="cta-three__shape-1">
      <div class="row justify-content-end">
        <div class="col-lg-5">
          <img src="../assets/images/resources/section1_electric_green_car.jpg" alt="" class="cta-three__moc" style="width:100%; border-radius: 12px">
        </div>
        <div class="col-lg-7">
          <div class="cta-three__content">
            <div class="block-title text-left">
              <span class="block-title__bubbles"></span>
<!--              <p>EV and Smart Mobility</p>-->
              <h3>The Future of Smart Mobility: D2S</h3>
            </div>

            <div class="cta-three__box-wrap">
              <div class="cta-three__box">
                <div class="cta-three__box-icon">
                    <font-awesome-icon :icon="['fas', 'charging-station']" size="3x"></font-awesome-icon>
                </div>
                <div class="cta-three__box-content">
                  <h3>D2S: unique business model for a smarter mobility</h3>
                  <p>Through our state-of-the-art Drive2Save business model, we will drive the adoption of EVs making the switch more accessible to everyone.</p>
                </div>
              </div>
              <div class="cta-three__box">
                <div class="cta-three__box-icon">
                    <font-awesome-icon :icon="['fas', 'leaf']" size="3x"></font-awesome-icon>
                </div>
                <div class="cta-three__box-content">
                  <h3>Become yourself a blockchain node</h3>
                  <p>thanks to our app, the users will become blockchain nodes and with the data collected we will support a more efficient and effective distribution of charging point networks.</p>
                </div>
              </div>
              <div class="cta-three__box">
                <div class="cta-three__box-icon">
                    <font-awesome-icon :icon="['fas', 'gift']" size="3x"></font-awesome-icon>
                </div>
                <div class="cta-three__box-content">
                    <h3>Benefits for EDT holders: D2S - a play on words</h3>
                    <p>Drive2Save, a clever twist in our model that makes it unique and meaningful. Paying charges with EDTs will make you earn reward points, that you can use 2 ways: 1. Grab unique discounts on charges and save cash; 2. Donate your points to back eco-friendly initiatives and make a positive impact on our planet!</p>
                </div>
              </div>
            </div>

<!--            <a href="#" class="thm-btn cta-three__btn">Discover More</a>-->

          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
    export default {
      name: "FeaturesOne"
    }
</script>

<style scoped>

</style>
