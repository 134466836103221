import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {faPhoneAlt} from "@fortawesome/free-solid-svg-icons";
import {faSignInAlt} from "@fortawesome/free-solid-svg-icons";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {faEnvelopeOpen} from "@fortawesome/free-solid-svg-icons";
import {faTelegramPlane} from "@fortawesome/free-brands-svg-icons";
import {faFacebookF} from "@fortawesome/free-brands-svg-icons";
import {faTwitter} from "@fortawesome/free-brands-svg-icons";
import {faLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import {faInstagram} from "@fortawesome/free-brands-svg-icons";
import {faMediumM} from "@fortawesome/free-brands-svg-icons";
import {faYoutube} from "@fortawesome/free-brands-svg-icons";
import {faRedditAlien} from "@fortawesome/free-brands-svg-icons";
import {faAngleDoubleRight} from "@fortawesome/free-solid-svg-icons";
import {faAngleUp} from "@fortawesome/free-solid-svg-icons";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {faGifts} from "@fortawesome/free-solid-svg-icons";
import {faGift} from "@fortawesome/free-solid-svg-icons";
import {faHandHoldingWater} from "@fortawesome/free-solid-svg-icons";
import {faTrophy} from "@fortawesome/free-solid-svg-icons";
import {faStar} from "@fortawesome/free-solid-svg-icons";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import {faShieldAlt} from "@fortawesome/free-solid-svg-icons";
import {faLightbulb} from "@fortawesome/free-solid-svg-icons";
import {faChargingStation} from "@fortawesome/free-solid-svg-icons";
import {faLeaf} from "@fortawesome/free-solid-svg-icons";
import {faHeart} from "@fortawesome/free-solid-svg-icons";
import {faPiggyBank} from "@fortawesome/free-solid-svg-icons";
import {faBuilding} from "@fortawesome/free-solid-svg-icons";
import {faKey} from "@fortawesome/free-solid-svg-icons";
import {faUsers} from "@fortawesome/free-solid-svg-icons";
import {faCode} from "@fortawesome/free-solid-svg-icons";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {faScroll} from "@fortawesome/free-solid-svg-icons";
import {faBalanceScaleRight} from "@fortawesome/free-solid-svg-icons";
import {faAward} from "@fortawesome/free-solid-svg-icons";
import {faCubes} from "@fortawesome/free-solid-svg-icons";
import {faPoundSign} from "@fortawesome/free-solid-svg-icons";
import {faBan} from "@fortawesome/free-solid-svg-icons";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {faGem} from "@fortawesome/free-solid-svg-icons";
import {faMedal} from "@fortawesome/free-solid-svg-icons";
import {faTag} from "@fortawesome/free-solid-svg-icons";
import {faFileDownload} from "@fortawesome/free-solid-svg-icons";
import {faBurn} from "@fortawesome/free-solid-svg-icons";


library.add(faPhoneAlt);
library.add(faSignInAlt);
library.add(faEnvelope);
library.add(faEnvelopeOpen);
library.add(faTelegramPlane);
library.add(faFacebookF);
library.add(faTwitter);
library.add(faLinkedinIn);
library.add(faInstagram);
library.add(faMediumM);
library.add(faYoutube);
library.add(faRedditAlien);
library.add(faAngleDoubleRight);
library.add(faAngleUp);
library.add(faAngleRight);
library.add(faGift);
library.add(faGifts);
library.add(faHandHoldingWater);
library.add(faTrophy);
library.add(faStar);
library.add(faLock);
library.add(faShieldAlt);
library.add(faLightbulb);
library.add(faChargingStation);
library.add(faLeaf);
library.add(faHeart);
library.add(faPiggyBank);
library.add(faBuilding);
library.add(faKey);
library.add(faUsers);
library.add(faCode);
library.add(faBars);
library.add(faScroll);
library.add(faBalanceScaleRight);
library.add(faAward);
library.add(faCubes);
library.add(faPoundSign);
library.add(faBan);
library.add(faHome);
library.add(faGem);
library.add(faMedal);
library.add(faTag);
library.add(faFileDownload);
library.add(faBurn);

export default FontAwesomeIcon;
