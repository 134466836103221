<template>
  <div class="page-wrapper">
    <HeaderSection />
    <router-view></router-view>
    <FooterSection />
  </div>
</template>

<script>
  import $ from "jquery";
  import HeaderSection from "src/components/Header.vue";
  import FooterSection from "src/components/Footer.vue";
  // import router from router;
  export default {
    name: "default-layout",
    components: {
      HeaderSection,
      FooterSection
    },
    head(){
      return {
        title: process.env.VUE_APP_NAME
      }
    },
    mounted() {
      let cookieBotScript = document.createElement('script');
      cookieBotScript.setAttribute('src', "https://consent.cookiebot.com/uc.js");
      cookieBotScript.id = "Cookiebot";
      cookieBotScript.type = "text/javascript";
      cookieBotScript.setAttribute("data-cbid", "239b3fa5-9734-4b71-be97-50a77546fcdd");
      cookieBotScript.setAttribute("data-blockingmode", "auto");
      document.head.appendChild(cookieBotScript);


      if ($('.accrodion-grp').length) {
        var accrodionGrp = $('.accrodion-grp');
        accrodionGrp.each(function () {
          var accrodionName = $(this).data('grp-name');
          var Self = $(this);
          var accordion = Self.find('.accrodion');
          Self.addClass(accrodionName);
          Self.find('.accrodion .accrodion-content').hide();
          Self.find('.accrodion.active').find('.accrodion-content').show();
          accordion.each(function () {
            $(this).find('.accrodion-title').on('click', function () {
              if ($(this).parent().parent().hasClass('active') === false) {
                $('.accrodion-grp.' + accrodionName).find('.accrodion').removeClass('active');
                $('.accrodion-grp.' + accrodionName).find('.accrodion').find('.accrodion-content').slideUp();
                $(this).parent().parent().addClass('active');
                $(this).parent().parent().find('.accrodion-content').slideDown();
              }


            });
          });
        });

      }

      // Submenu Dropdown Toggle
      if ($('.main-nav__main-navigation li.dropdown ul').length) {
        $('.main-nav__main-navigation li.dropdown').children('a').append('<button class="dropdown-btn"><font-awesome-icon :icon="[\'fas\', \'angle-right\']"></font-awesome-icon></a></button>');
      }

      // mobile menu
      if ($('.main-nav__main-navigation').length) {
        let mobileNavContainer = $('.mobile-nav__container');
        let mainNavContent = $('.main-nav__main-navigation').html();

        mobileNavContainer.append(mainNavContent);

        //Dropdown Button
        mobileNavContainer.find('li.dropdown .dropdown-btn').on('click', function (e) {
          $(this).toggleClass('open');
          $(this).parent().parent().children('ul').slideToggle(500);
          e.preventDefault();
        });
      }

      if ($('.stricky').length) {
        $('.stricky').addClass('original').clone(true).insertAfter('.stricky').addClass('stricked-menu').removeClass('original');
      }


      if ($('.side-menu__toggler').length) {
        $('.side-menu__toggler').on('click', function (e) {
          $('.side-menu__block').toggleClass('active');
          e.preventDefault();
        });
      }

      if ($('.side-menu__block-overlay').length) {
        $('.side-menu__block-overlay').on('click', function (e) {
          $('.side-menu__block').removeClass('active');
          e.preventDefault();
        });
      }


      if ($('.scroll-to-target').length) {
        $(".scroll-to-target").on('click', function (e) {
          var target = $(this).attr('data-target');
          // animate
          $('html, body').animate({
            scrollTop: $(target).offset().top
          }, 1000);

          e.preventDefault();

        });
      }

      let router = this.$router;

      function SmoothMenuScroll() {
        var anchor = $('.scrollToLink');
        if (anchor.length) {

          anchor.children('a').bind('click', function (event) {

            var headerH = '0';
            if ($(window).scrollTop() > 10) {
              headerH = '67';
            } else {
              headerH = '100';
            }
            var target = $(this);

            if ($(target.attr('href')).length) {
              $('html, body').stop().animate({
                scrollTop: $(target.attr('href')).offset().top - headerH + 'px'
              }, 1200, 'easeInOutExpo');
              anchor.removeClass('current');
              target.parent().addClass('current');
              event.preventDefault();
            }
            else
              router.push({path: '/', hash: target.attr('href')});
          });
        }
      }
      SmoothMenuScroll();


      function OnePageMenuScroll() {
        var windscroll = $(window).scrollTop();
        if (windscroll >= 100) {
          var menuAnchor = $('.one-page-scroll-menu .scrollToLink').children('a');
          menuAnchor.each(function () {
            // grabing section id dynamically
            var sections = $(this).attr('href');
            $(sections).each(function () {
              // checking is scroll bar are in section
              if ($(this).offset().top <= windscroll + 100) {
                // grabing the dynamic id of section
                var Sectionid = $(sections).attr('id');
                // removing current class from others
                $('.one-page-scroll-menu').find('li').removeClass('current');
                // adding current class to related navigation
                  $('.one-page-scroll-menu').find("a[href='#" + Sectionid + "']").parent().addClass('current');
              }
            });
          });
        } else {
          $('.one-page-scroll-menu li.current').removeClass('current');
          $('.one-page-scroll-menu li:first').addClass('current');
        }
      }



      if ($('.search-popup__toggler').length) {
        $('.search-popup__toggler').on('click', function (e) {
          $('.search-popup').addClass('active');
          e.preventDefault();
        });
      }

      if ($('.search-popup__overlay').length) {
        $('.search-popup__overlay').on('click', function (e) {
          $('.search-popup').removeClass('active');
          e.preventDefault();
        });
      }
      if ($('.counter').length) {
        $('.counter').counterUp({
          delay: 10,
          time: 3000
        });
      }


      if ($('.img-popup').length) {
        var groups = {};
        $('.img-popup').each(function () {
          var id = parseInt($(this).attr('data-group'), 10);

          if (!groups[id]) {
            groups[id] = [];
          }

          groups[id].push(this);
        });


        $.each(groups, function () {

          $(this).magnificPopup({
            type: 'image',
            closeOnContentClick: true,
            closeBtnInside: false,
            gallery: {
              enabled: true
            }
          });

        });

      }

      if ($('[data-toggle="tooltip"]').length) {
        $('[data-toggle="tooltip"]').tooltip();
      }


      if ($('.video-popup').length) {
        $('.video-popup').magnificPopup({
          disableOn: 700,
          type: 'iframe',
          mainClass: 'mfp-fade',
          removalDelay: 160,
          preloader: true,

          fixedContentPos: false
        });
      }



      $(window).on('scroll', function () {
        if ($('.stricked-menu').length) {
          var headerScrollPos = 100;
          var stricky = $('.stricked-menu');
          if ($(window).scrollTop() > headerScrollPos) {
            stricky.addClass('stricky-fixed');
          } else if ($(this).scrollTop() <= headerScrollPos) {
            stricky.removeClass('stricky-fixed');
          }
        }
        OnePageMenuScroll();
        if ($('.scroll-to-top').length) {
          var strickyScrollPos = 100;
          if ($(window).scrollTop() > strickyScrollPos) {
            $('.scroll-to-top').fadeIn(500);
          } else if ($(this).scrollTop() <= strickyScrollPos) {
            $('.scroll-to-top').fadeOut(500);
          }
        }

      });

      $(window).on('load', function () {

        // if ($('#switch-toggle-tab').length) {
        //   var toggleSwitch = $('#switch-toggle-tab label.switch');
        //   var TabTitle = $('#switch-toggle-tab li');
        //   var monthTabTitle = $('#switch-toggle-tab li.month');
        //   var yearTabTitle = $('#switch-toggle-tab li.year');
        //   var monthTabContent = $('#month');
        //   var yearTabContent = $('#year');
        //   // hidden show deafult;
        //   monthTabContent.fadeIn();
        //   yearTabContent.fadeOut();
        //
        //   function toggleHandle() {
        //     if (toggleSwitch.hasClass('on')) {
        //       yearTabContent.fadeOut();
        //       monthTabContent.fadeIn();
        //       monthTabTitle.addClass('active');
        //       yearTabTitle.removeClass('active');
        //     } else {
        //       monthTabContent.fadeOut();
        //       yearTabContent.fadeIn();
        //       yearTabTitle.addClass('active');
        //       monthTabTitle.removeClass('active');
        //     }
        //   }
        //   monthTabTitle.on('click', function () {
        //     toggleSwitch.addClass('on').removeClass('off');
        //     toggleHandle();
        //     return false;
        //   });
        //   yearTabTitle.on('click', function () {
        //     toggleSwitch.addClass('off').removeClass('on');
        //     toggleHandle();
        //     return false;
        //   });
        //   toggleSwitch.on('click', function () {
        //     toggleSwitch.toggleClass('on off');
        //     toggleHandle();
        //   });
        // }

        if ($('.testimonials-slider').length) {
          $('.testimonials-slider').bxSlider({
            // adaptiveHeight: true,
            auto: true,
            controls: true,
            nextText: '<i class="zimed-icon-right-arrow"></i>',
            prevText: '<i class="zimed-icon-left-arrow"></i>',
            pause: 5000,
            speed: 500,
            pager: true,
            pagerCustom: '.testimonials-slider-pager-one, .testimonials-slider-pager-two'
          });
        }

        if ($('.thm__owl-carousel').length) {
          $('.thm__owl-carousel').owlCarousel({
            loop: true,
            margin: 43,
            nav: false,
            dots: true,
            autoWidth: false,
            autoplay: true,
            slideBy: 'page',
            smartSpeed: 700,
            responsiveClass:true,
            responsive: {
              0 : {
                items: 1
              },
              480 :{
                items: 2
              },
              600 :{
                items: 3
              },
              // 991 : {
              //   items: 4
              // },
              // 1000 : {
              //   items: 5
              // },
              // 1200 : {
              //   items: 5
              // }
            }
          });
        }

        if ($('.side-menu__block-inner').length) {
          $('.side-menu__block-inner').mCustomScrollbar({
            axis: 'y',
            theme: 'dark'
          });
        }

        if ($('.custom-cursor__overlay').length) {

          // / cursor /
          var cursor = $(".custom-cursor__overlay .cursor"),
            follower = $(".custom-cursor__overlay .cursor-follower");

          var posX = 0,
            posY = 0;

          var mouseX = 0,
            mouseY = 0;

          // TweenMax.to({}, 0.016, {
          //   repeat: -1,
          //   onRepeat: function () {
          //     posX += (mouseX - posX) / 9;
          //     posY += (mouseY - posY) / 9;
          //
          //     TweenMax.set(follower, {
          //       css: {
          //         left: posX - 22,
          //         top: posY - 22
          //       }
          //     });
          //
          //     TweenMax.set(cursor, {
          //       css: {
          //         left: mouseX,
          //         top: mouseY
          //       }
          //     });
          //
          //   }
          // });

          $(document).on("mousemove", function (e) {
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            mouseX = e.pageX;
            mouseY = e.pageY - scrollTop;
          });
          $("button, a").on("mouseenter", function () {
            cursor.addClass("active");
            follower.addClass("active");
          });
          $("button, a").on("mouseleave", function () {
            cursor.removeClass("active");
            follower.removeClass("active");
          });
          $(".custom-cursor__overlay").on("mouseenter", function () {
            cursor.addClass("close-cursor");
            follower.addClass("close-cursor");
          });
          $(".custom-cursor__overlay").on("mouseleave", function () {
            cursor.removeClass("close-cursor");
            follower.removeClass("close-cursor");
          });
        }
        if ($('.preloader').length) {
          $('.preloader').fadeOut();
        }


      });
    }
  }
</script>
