<template>
  <div class="page-wrapper">
    <PageHeader :title="title" />
    <div class="row">

      <div class="col-12 p-5 text-center">
        <div ref="cookieScript"></div>
<!--        <h5>{{ $t('cookie_not_available') }}</h5>-->

<!--        <router-link to="/" class="thm-btn cta-three__btn"><font-awesome-icon :icon="['fas', 'home']"></font-awesome-icon> Back to Home</router-link>-->
      </div>

    </div>
    <router-view></router-view>
  </div>
</template>
<script>
  import PageHeader from "src/components/PageHeader.vue";
  export default {
    components: {
      PageHeader
    },
    data(){
      return {
        title: "Cookie Policy"
      }
    },
    mounted() {
      let cookieBotScript = document.createElement('script');
      cookieBotScript.setAttribute('src', "https://consent.cookiebot.com/239b3fa5-9734-4b71-be97-50a77546fcdd/cd.js");
      cookieBotScript.id = "CookieDeclaration";
      cookieBotScript.async = true;
      cookieBotScript.type = "text/javascript";
      this.$refs.cookieScript.insertAdjacentElement('beforeend', cookieBotScript);

      window.scrollTo(0, 0);
    }
  }
</script>
