import { createApp } from "vue";
import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import Cookies from "js-cookie";
// import GlobalComponents from "./globalComponents";
// import GlobalDirectives from "./globalDirectives";

// Create a new store instance.
const store = createStore({
  state() {
    return {
      webPreferences: {
        lang: "en",
        currency: "€",
      },
      isLoggedIn: false,
      user: {
        id: "",
        email: "",
        iri: "",
        emailVerified: null,
        roles: [],
        subscription: null,
        company: null
      },
    };
  },
  mutations: {
    auth_request(state) {
      state.isLoggedIn = false;
    },
    auth_success(state, user) {
      state.isLoggedIn = true;
      state.user.id = user.id;
      state.user.email = user.email;
      state.user.iri = user.iri;
      state.user.emailVerified = user.emailVerified;
      state.user.subscription = user.subscription;
      state.user.company = user.company;
      state.user.roles = user.roles;
    },
    auth_error(state) {
      state.isLoggedIn = false;
    },
    logout(state) {
      state.isLoggedIn = false;
      state.user.id = "";
      state.user.email = "";
      state.user.iri = "";
      state.user.emailVerified = null;
      state.user.subscription = null;
      state.user.company = null;
      state.user.roles = [];
    },
    setLang(state, lang) {
      state.webPreferences.lang = lang;
    },
    setSubscription(state, subscription) {
      state.user.subscription = subscription;
    },
    setCompany(state, company) {
      state.user.company = company;
    }
  },
  getters: {
    isLoggedIn: (state) => state.isLoggedIn,
    getEmail: (state) => state.user.email,
    getUserId: (state) => state.user.id,
    getUserIri: (state) => state.user.iri,
    isUserEmailVerified: (state) => state.user.emailVerified,
    getUserRoles: (state) => state.user.roles,
    getLang: (state) => { return state.webPreferences.lang; },
    hasRole: (state) => (role) => {return state.user.roles.includes(role);},
    hasSubscription: (state) => state.user.subscription !== null,
    hasCompany: (state) => state.user.company !== null,
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) =>
          Cookies.set(key, value, {
            expires: 365,
            secure: true,
            sameSite: "strict",
          }),
        removeItem: (key) => Cookies.remove(key),
      },
      paths: ["webPreferences"],
    }),
  ],
});

export default store;
