<template>
  <section class="mt-5">
    <div class="container">

        <div class="row">
            <div class="col-12 text-center" style="margin-bottom: -22px;">
                <h4 style="color: #085e57; font-weight: bold"><span class="p-3" style="background-color: white">Token Information</span></h4>
            </div>
        </div>


        <div class="row token-data-box">
<!--            <div class="col-md-5 col-lg-4 col-xl-3 m-auto token-data-distribution-box">-->
<!--                <p><strong>Private Sale</strong>: 4,000M</p>-->
<!--                <p><strong>Pre-Sale</strong>: 12,000M</p>-->
<!--                <p><strong>Team (12m lock)</strong>: 600M</p>-->
<!--                <p><strong>Liquidity on DEXs</strong>: 1,400M</p>-->
<!--                <p><strong>Reserve</strong>: 2,000M</p>-->
<!--                <p><strong>Total Supply:</strong> 20,000M</p>-->
<!--            </div>-->

            <div class="col-md-10 col-lg-8 col-xl-9 m-auto token-data-general-info-box">
                <div class="row">
                    <div class="col-sm-6 col-lg-4 col-xl-3 m-auto text-center">
                        <p><strong><font-awesome-icon :icon="['fas', 'award']"></font-awesome-icon> Symbol</strong></p>
                        <p>EDT</p>
                    </div>

                    <div class="col-sm-6 col-lg-4 col-xl-3 m-auto text-center">
                        <p><strong><font-awesome-icon :icon="['fas', 'cubes']"></font-awesome-icon> Type</strong></p>
                        <p>BEP20</p>
                    </div>

                    <div class="col-sm-6 col-lg-4 col-xl-3 m-auto text-center">
                        <p><strong><font-awesome-icon :icon="['fas', 'balance-scale-right']"></font-awesome-icon> Decimals</strong></p>
                        <p>18</p>
                    </div>

<!--                    <div class="col-sm-6 col-lg-4 col-xl-3 m-auto text-center">-->
<!--                        <p><strong><font-awesome-icon :icon="['fas', 'tag']"></font-awesome-icon> Pre-Sale Price</strong></p>-->
<!--                        <p>0.0013 €</p>-->
<!--                    </div>-->

                    <div class="col-sm-6 col-lg-4 col-xl-3 m-auto text-center">
                        <p><strong><font-awesome-icon :icon="['fas', 'scroll']"></font-awesome-icon> Smart Contract</strong></p>
                        <a href="https://bscscan.com/token/0x936Dcfc3fF6A8DA20A945d867BDfEA09988Ffa0c" target="_blank" class="text-black-50 font-weight-bold">See</a>
                    </div>

                    <div class="col-sm-6 col-lg-4 col-xl-3 m-auto text-center">
                        <p><strong><font-awesome-icon :icon="['fas', 'shield-alt']"></font-awesome-icon> Security Audit</strong></p>
<!--                        <a href="https://github.com/interfinetwork/smart-contract-audits/blob/audit-updates/EDriveToken_0x936Dcfc3fF6A8DA20A945d867BDfEA09988Ffa0c.pdf" target="_blank" class="text-black-50 font-weight-bold">See</a>-->
                        <a href="https://github.com/interfinetwork/project-delivery-data/blob/main/EDrive/EDriveToken_AuditReport_InterFi.pdf" target="_blank" class="text-black-50 font-weight-bold">See</a>
                    </div>
                </div>
            </div>


        </div>


    </div>
  </section>
</template>

<script>
    export default {
      name: "token-data"
    }
</script>

<style scoped>

</style>
