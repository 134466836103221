import { createRouter, createWebHistory } from "vue-router";
import NotFoundPage from "src/pages/NotFoundPage";
import AuthLayout from "src/layouts/defaultLayout";
import Home from "src/pages/Home-page";
import PrivacyPolicy from "src/pages/PrivacyPolicy";
import Terms from "src/pages/Terms";
import CookiePolicy from "src/pages/CookiePolicy";
import Whitepaper from "src/pages/Whitepaper";
import CompanyRegistration from "src/pages/VisuraCamerale";
// import store from "src/store";
// import axios from "axios";


let home = {
	path: "/",
	component: AuthLayout,
	name: "Home",
	meta: {
      requiresAuth: false,
    },
	children: [
        {
          path: "/",
          name: "Home",
          component: Home,
          meta: {
            requiresAuth: false,
          },
        },
        {
            path: "/privacy_policy",
            name: "PrivacyPolicy",
            component: PrivacyPolicy,
            meta: {
                requiresAuth: false,
            },
        },
        {
            path: "/cookie_policy",
            name: "CookiePolicy",
            component: CookiePolicy,
            meta: {
                requiresAuth: false,
            },
        },
    ]
};



const routes = [
  home,
  {path: "/whitepaper", name: "Whitepaper", component: AuthLayout, children: [{path: "", component: Whitepaper, name: "Whitepaper"}]},
  {path: "/companyRegistration", name: "CompanyRegistration", component: AuthLayout, children: [{path: "", component: CompanyRegistration, name: "CompanyRegistration"}]},
  {path: "/terms", name: "Terms", component: AuthLayout, children: [{path: "", component: Terms, name: "Terms"}]},
  { path: '/:pathMatch(.*)*', name: "NotFound", component: AuthLayout, children: [{path: "", component: NotFoundPage, name: "NotFound"}] },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
});

/*
router.beforeEach(async (to, from) => {
  // in this way, extract the variable requireRole from to.meta, not all the attributes of to.meta!
  const { requiresRole } = to.meta;
  let loggedUser = store.getters.isLoggedIn;

  // name of the routes to avoid if the user is already logged in
  // note: confirm_registration can be accessed also if the user is loggedIn.
  // let listSpecialPaths = ["Register", "Login", "TwoFA", "decline_registration", "restore_account"];
  let listSpecialPaths = ["Register", "Login", "reset_password"];

  if (!to.meta.requiresAuth && !listSpecialPaths.includes(to.name))
    return true;

  if (!loggedUser) {
    await axios
      .post(process.env.VUE_APP_SERVER_BASE_URL + "me/", {})
      .then((response) => {
        store.commit("auth_success", response.data.user);
        loggedUser = true;
      })
      .catch((error) => {});
  }

  if (!loggedUser && !to.meta.requiresAuth) return true;

  // redirect to login page if not logged in and trying to access a restricted page
  if (!loggedUser && to.meta.requiresAuth)
    return { path: "/login", query: { redirect: to.path } };

  // redirect to dashboard if the user is logged in and is trying to access to a "special path".
  if (listSpecialPaths.includes(to.name) && loggedUser)
      return "/dashboard";


  // if we need an array of roles to check, use this:
  // let checker = (arr, target) => target.every(v => arr.includes(v));

  // check if route is restricted by role
  if (requiresRole && !store.getters.hasRole(requiresRole))
    return false; // role not authorized

  return true;
});
*/

export default router;
