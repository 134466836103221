<template>
  <div>
    <div class="preloader">
      <div class="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>

    <header class="main-nav__header-one ">
      <nav class="header-navigation stricky">
        <div class="container">
          <!-- Brand and toggle get grouped for better mobile display -->
          <div class="main-nav__logo-box">
            <a href="/" class="main-nav__logo">
              <img src="./../assets/images/logo-no-text.png" style="width: 60px" alt=""/>
              <span class="text-white pl-1">{{ name }}</span>
            </a>
            <a href="#" class="side-menu__toggler"><font-awesome-icon :icon="['fas', 'bars']"></font-awesome-icon></a>
          </div>
          <!-- Collect the nav links, forms, and other content for toggling -->
          <div class="main-nav__main-navigation">
            <ul class="one-page-scroll-menu main-nav__navigation-box">
              <li class="current scrollToLink">
                <a href="#banner">Home</a>
              </li>
              <li class="scrollToLink">
                <a href="#features">Goals</a>
              </li>
              <li class="scrollToLink">
                <a href="#roadmap">Roadmap</a>
              </li>
              <li class="scrollToLink">
                <a href="#team">Team</a>
              </li>

<!--              <li class="dropdown scrollToLink">-->
<!--                <a href="#blog">Blog</a>-->
<!--                <ul>-->
<!--                  <li><nuxt-link to="/blog">Blog Page</nuxt-link></li>-->
<!--                  <li><nuxt-link to="/blog-details">Blog Details</nuxt-link></li>-->
<!--                </ul>&lt;!&ndash; /.sub-menu &ndash;&gt;-->
<!--              </li>-->

              <li class="">
                <a :href="contact_url">Contact</a>
              </li>

              <li class="">
                <a href="https://www.edrivetoken-projects.io/" target="_blank">Showroom</a>
              </li>

            </ul>
          </div>
          <div class="main-nav__right">
            <a :href="register_url" class="thm-btn header__btn scroll-to-target">APP</a>
          </div>
        </div>

      </nav>
    </header>
  </div>
</template>

<script>
    export default {
      name: "header-section",
        data() {
            return {
                name: process.env.VUE_APP_NAME,
                register_url: process.env.VUE_APP_PLATFORM_BASE_URL +  process.env.VUE_APP_PLATFORM_REGISTRATION_URL + (this.$route.query.ref ? '?ref=' + this.$route.query.ref : ""),
                contact_url: process.env.VUE_APP_PLATFORM_BASE_URL +  process.env.VUE_APP_PLATFORM_CONTACT_URL + (this.$route.query.ref ? '?ref=' + this.$route.query.ref : ""),
            }
        }
    }
</script>

<style scoped>

</style>
